import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './AppContent.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';

class AppContent extends Component {
    render() {

        return (
            <div className="container-fluid">
                <div className="row">
                    {/* <Navbar />
                    <Sidebar/> */}
                    { this.props.children }
                </div>
            </div>
        );
    }
}

export default AppContent;