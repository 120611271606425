import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import AppContent from './pages/app-content/AppContent';
import Auth from './pages/auth/Auth';
import Signup from './pages/auth/Signup';
import Navbar from './components/navbar/Navbar';
import Accueil from './pages/espace-secretaire/accueil/Accueil';
import AccueilChercheur from './pages/espace-chercheur/accueil/AccueilChercheur';
import AccueilMembre from './pages/espace-membre/accueil/AccueilMembre';
import Inbox from './pages/espace-secretaire/inbox/Inbox';
import ListeDepots from './pages/espace-secretaire/depot/ListeDepots';
import inboxChercheur from './pages/espace-chercheur/inbox/inboxChercheur';
import ListeProtocolesChercheur from './pages/espace-chercheur/protocoles/ListeProtocolesChercheur';
import Depot from './pages/espace-secretaire/depot/Depot';
import ListeEnregistrements from './pages/espace-secretaire/enregistrements/ListeEnregistrements';
import Enregistrements from './pages/espace-secretaire/enregistrements/Enregistrements';
import Archives from './pages/espace-secretaire/archives/Archives';
import ListeMembres from './pages/espace-secretaire/membre/ListeMembres';
import ListeSession from './pages/espace-secretaire/sessions/ListeSession';
import Session from './pages/espace-secretaire/sessions/Session';
import EditSession from './pages/espace-secretaire/sessions/EditSession';
import newSession from './pages/espace-secretaire/sessions/newSession';
import Membre from './pages/espace-secretaire/membre/Membre';
import Calendrier from './pages/espace-secretaire/calendrier/Calendrier';
import ParametresCompte from './pages/auth/ParametresCompte';
import protocoleChercheur from './pages/espace-chercheur/protocoles/protocoleChercheur';
import ModifierProtocole from './pages/espace-chercheur/protocoles/ModifierProtocole';
import NewProtocole from './pages/espace-chercheur/protocoles/NewProtocole';
import CalendrierChercheur from './pages/espace-chercheur/calendrier/CalendrierChercheur';
import ListeProtocolesMembre from './pages/espace-membre/protocoles/ListeProtocolesMembre';
import ProtocoleMembre from './pages/espace-membre/protocoles/ProtocoleMembre';
import CalendrierMembre from './pages/espace-membre/calendrier/CalendrierMembre';
import ListeAmendementsMajeursChercheur from './pages/espace-chercheur/protocoles/ListeAmendementsMajeursChercheur';
import ListeAmendementsMajeursSecretaire from './pages/espace-secretaire/depot/ListeAmendementsMajeursSecretaire';
import CompleterAmendementMajeur from './pages/espace-chercheur/protocoles/CompleterAmendementMajeur';
import Notifications from './pages/auth/Notifications';
import MotDePasseOublie from './pages/auth/MotDePasseOublie';

class App extends Component  {

    renderRoutes = () => (
        <>
            {/* Routes communs... */}
            <Route path="/parametres" exact component={ParametresCompte} />
            <Route path="/notifications" exact component={Notifications} />

            {/* Routes du secretaire ... */}
            <Route path="/secretaire" exact component={Accueil} />
            <Route path="/secretaire/inbox" component={Inbox} />
            <Route path="/secretaire/depots" component={ListeDepots} />
            <Route path="/secretaire/depot/:depot_id" component={Depot} />
            <Route path="/secretaire/amendements-majeurs" component={ListeAmendementsMajeursSecretaire} />
            <Route path="/secretaire/enregistrements" component={ListeEnregistrements} />
            <Route path="/secretaire/enregistrement/:protocole_id" component={Enregistrements} />
            <Route path="/secretaire/archives" component={Archives} />
            <Route path="/secretaire/comites" component={ListeMembres} />
            <Route path="/secretaire/sessions" component={ListeSession} />
            <Route path="/secretaire/session/:session_id" component={Session} />
            <Route path="/secretaire/modifier-session/:session_id" component={EditSession} />
            <Route path="/secretaire/new-session" component={newSession} />
            <Route path="/secretaire/new-membre" component={Membre} />
            <Route path="/secretaire/calendrier" component={Calendrier} />

            {/* Routes du chercheurs ... */}
            <Route path="/chercheur" exact component={AccueilChercheur} />
            <Route path="/chercheur/protocoles" exact component={ListeProtocolesChercheur} />
            <Route path="/chercheur/amendements-majeurs" exact component={ListeAmendementsMajeursChercheur} />
            <Route path="/chercheur/protocole/:protocole_id/:type" exact component={protocoleChercheur} />
            <Route path="/chercheur/modifier-soumission/:soumission_id" exact component={ModifierProtocole} />
            <Route path="/chercheur/new-protocole" exact component={NewProtocole} />
            <Route path="/chercheur/new-protocole/:soumission_id" exact component={CompleterAmendementMajeur} />
            <Route path="/chercheur/inbox" exact component={inboxChercheur} />
            <Route path="/chercheur/calendrier" component={CalendrierChercheur} />

            {/* Routes du membre ... */}
            <Route path="/membre" exact component={AccueilMembre} />
            <Route path="/membre/protocoles" exact component={ListeProtocolesMembre} />
            <Route path="/membre/protocole/:protocole_id" exact component={ProtocoleMembre} />
            <Route path="/membre/calendrier" component={CalendrierMembre} />
        </>
    )

    render() {
        let profil = sessionStorage.getItem('profil')
        return (
            // <Router basename="/cners-app/">
            <Router>
                <Switch>
                    <Route path="/login" exact component={Auth} />
                    <Route path="/signup" exact component={Signup} />
                    <Route path="/mot-de-passe-oublie" exact component={MotDePasseOublie} />

                    <AppContent>
                        <Route component={({ match }) =>
                            this.renderRoutes()
                        }/>
                    </AppContent>
                </Switch>
            </Router>
        );
    }
}

export default App;
