import React, { Component } from 'react';

import './Inbox.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import Chat from '../../../components/chat/Chat';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class Inbox extends Component {

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
    }
    
    render() {
        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <Chat />
                </div>
            </>
        );
    }
}

export default Inbox;