import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {url} from '../../Config';

import './SessionOverview.css';

class SessionOverview extends Component {
    constructor() {
        super();
        this.state = {
            session: {}
        }
    }
    
    componentDidMount() {
        this.setState({session: this.props.session});
    }

    render() {
        const {session} = this.state;

        return (
            <div className="session-overview-box">
                <img className="session-overview-img" src={`${url}/public/img/${session.image}`} />
                <div className="sessions-overview-infos">
                    <span className="objet"><Link to={`/secretaire/session/${session.id}`}>{session.date}: </Link></span>
                    <span className="apercu">{session.objet}</span>
                    <div className="level">
                        <div className="clearfix">
                            { 
                                session.etat === 'passe' 
                                ?   <span className="text-secondary float-right">Session Passée</span>
                                :   <Link to={`/secretaire/modifier-session/${session.id}`} className="blue-color float-right">Modifier la session</Link>
                            }
                        </div>
                        <div className="level-bar">
                            { session.etat === 'passe' ? <span className="done bg-secondary"></span> : <span className="todo bcg-blue"></span>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SessionOverview;