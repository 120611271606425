import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

import './Sidebar.css';

class Sidebar extends Component {
    constructor() {
        super();
        this.state = {
            profil: ''
        }
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        this.setState({profil});
    }

    getUrlName = () => {
        const path = window.location.pathname;
        let links = document.querySelectorAll('.sidebar-link');
        let element = null;

        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            if(link.pathname == path) {
                element = link;
                break;
            }
        }

        let oldElementActive = document.querySelector('.sidebar-link.active');
        if (element !== null) {
            if(oldElementActive && oldElementActive != element) {
                oldElementActive.classList.remove('active')
                element.classList.add('active');
            }
        }  
    }

    activeTab = (e) => {
    }

    renderSecretaireSidebar = () => {
        return (
            <Fragment>
                <li className="sidebar-list-item">
                    <Link to="/secretaire" className="sidebar-link active" onClick={this.activeTab}>
                        <i className="fas fa-home mr-2 text-icon"></i><span>Accueil</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/secretaire/inbox" className="sidebar-link" onClick={this.activeTab}>
                        <i className="far fa-clipboard pl-1 mr-2 text-icon"></i><span>Messagerie</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/secretaire/depots" className="sidebar-link" onClick={this.activeTab}>
                        <i className="fas fa-download mr-2 text-icon"></i><span>Protocoles soumis</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/secretaire/enregistrements" className="sidebar-link" onClick={this.activeTab}>
                        <i className="fas fa-copy mr-2 text-icon"></i><span>Protocoles enregistrés</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/secretaire/calendrier" className="sidebar-link" onClick={this.activeTab}>
                        <i className="far fa-calendar-alt mr-2 text-icon"></i><span>Agenda</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/secretaire/archives" className="sidebar-link" onClick={this.activeTab}>
                        <i className="fas fa-file-archive mr-2 text-icon"></i><span>Archives</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/secretaire/comites" className="sidebar-link" onClick={this.activeTab}>
                        <i className="fas fa-users mr-2 text-icon"></i><span>Comité</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/secretaire/sessions" className="sidebar-link" onClick={this.activeTab}>
                        <i class="fas fa-comments mr-2 text-icon"></i><span>Sessions</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/notifications" className="sidebar-link" onClick={this.activeTab}>
                        <i class="fas fa-bell mr-2 text-icon"></i><span>Notifications</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/secretaire/amendements-majeurs" className="sidebar-link" onClick={this.activeTab}>
                        <i class="fas fa-edit mr-2 text-icon"></i><span>Amendements</span>
                    </Link>
                </li>
            </Fragment>
        )
    }

    renderChercheurSidebar = () => {
        return (
            <Fragment>
                <li className="sidebar-list-item">
                    <Link to="/chercheur" className="sidebar-link active" onClick={this.activeTab}>
                        <i className="fas fa-home mr-2 text-icon"></i><span>Accueil</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/chercheur/protocoles" className="sidebar-link" onClick={this.activeTab}>
                        <i className="fas fa-file-alt mr-2 text-icon"></i><span>Protocoles</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/chercheur/inbox" className="sidebar-link" onClick={this.activeTab}>
                        <i className="far fa-clipboard mr-2 text-icon"></i><span>Messagerie</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/chercheur/calendrier" className="sidebar-link" onClick={this.activeTab}>
                        <i className="far fa-calendar-alt mr-2 text-icon"></i><span>Agenda</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/notifications" className="sidebar-link" onClick={this.activeTab}>
                        <i class="fas fa-bell mr-2 text-icon"></i><span>Notifications</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/chercheur/amendements-majeurs" className="sidebar-link" onClick={this.activeTab}>
                        <i class="fas fa-edit mr-2 text-icon"></i><span>Amendements</span>
                    </Link>
                </li>
            </Fragment>
        )
    }

    renderMembreSidebar = () => {
        return (
            <Fragment>
                <li className="sidebar-list-item">
                    <Link to="/membre" className="sidebar-link active" onClick={this.activeTab}>
                        <i className="fas fa-home mr-2 text-icon"></i><span>Accueil</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/membre/protocoles" className="sidebar-link" onClick={this.activeTab}>
                        <i className="fas fa-file-alt mr-2 text-icon"></i><span>Protocoles</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/membre/calendrier" className="sidebar-link" onClick={this.activeTab}>
                        <i className="far fa-calendar-alt mr-2 text-icon"></i><span>Agenda</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/notifications" className="sidebar-link" onClick={this.activeTab}>
                        <i className="fas fa-bell mr-2 text-icon"></i><span>Notifications</span>
                    </Link>
                </li>
            </Fragment>
        )
    }

    render() {
        const {profil} = this.state;
        this.getUrlName();
        return (
            <div id="sidebar-content" className="col-md-2">
                <div id="sidebar" className={`sidebar pb-3 shadow ${profil === 'secretaire' ? 'bg-white' : 'bcg-blue'}`}>
                    <ul className="sidebar-menu list-unstyled">

                        {
                            profil === 'secretaire' 
                            ? this.renderSecretaireSidebar()      /* LIENS DE L'ESPACE DU SECRETAIRE ... */
                            : profil === 'chercheur' 
                            ? this.renderChercheurSidebar()       /* LIENS DE L'ESPACE DU CHERCHEUR ... */
                            : this.renderMembreSidebar()          /* LIENS DE L'ESPACE DU MEMBRE ... */
                        }

                    </ul>
                </div>
            </div>
        );
    }
}

export default Sidebar;