import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../Config';

import ListeOverviews from '../../components/liste-overviews/ListeOverviews';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';

class ParametresCompte extends Component {
    constructor() {
        super();
        this.state = {
            compte_id: '',
            prenom: '',
            nom: '',
            username: '',
            password: '',
            avatar: '',
            email: '',
            telephone: '',
            error: '',
            redirect: false,
            isLoading: false,
            success: '',
            user: null
        }
    }

    componentDidMount(){
        const token = sessionStorage.getItem('token');
        const username = sessionStorage.getItem('username');

        Axios.get(`${url}/api/get-compte?token=${token}&username=${username}`).then(
            res => {
                const {compte_id} = res.data;
                this.setState({ compte_id });
            }
        )
    }

    getUserInfos = () => {
        const token = sessionStorage.getItem('token');
        const username = sessionStorage.getItem('username');

        Axios.get(`${url}/api/user?token=${token}&&username=${username}`).then(
            res => {
                const {user} = res.data;
                this.setState({ user });
            }
        )
    }

    onHandleChange = (e) => {
        const {name, value} = e.target;
        this.setState({error: '', success: ''});

        switch (name) {
            case 'prenom':
                this.setState({prenom: value});
                break;
            case 'nom':
                this.setState({nom: value});
                break;
            case 'username':
                this.setState({username: value});
                break;
            case 'password':
                this.setState({password: value});
                break;
            case 'email':
                this.setState({email: value});
                break;
            case 'telephone':
                this.setState({telephone: value});
                break;
            case 'avatar':
                this.setState({avatar: e.target.files[0]});
                break;
            default:
                break;
        }
    }
    
    onUpdateInfos = (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('token');
        this.setState({isLoading: true, success: ''});
        const {prenom, nom, username, password, email, telephone, avatar, error, compte_id} = this.state;
        
        let formData = new FormData();
        formData.append('compte_id', compte_id);
        if(prenom !== ''){ formData.append('prenom', prenom); }
        if(nom !== ''){ formData.append('nom', nom); }
        if(username !== ''){ formData.append('username', username); }
        if(password !== ''){ formData.append('password', this.state.password); }
        if(email !== ''){ formData.append('email', this.state.email); }
        if(telephone !== ''){ formData.append('telephone', this.state.telephone); }
        if(avatar !== ''){ formData.append('avatar', this.state.avatar); }

        Axios.post(`${url}/api/edit-infos?token=${token}`, formData).then(
            res => {
                if(res.data.status === 'error'){
                    this.setState({error: res.data.error});
                    setTimeout(() => {
                        this.setState({ isLoading: false, error: '' })
                    }, 4000);
                } else {
                    if(username !== ''){ sessionStorage.setItem('username', username); }                    
                    this.setState({
                        isLoading: false,
                        success: res.data.message,
                        prenom: '',
                        nom: '',
                        username: '',
                        password: '',
                        email: '',
                        telephone: '',
                        avatar: ''
                    });

                    this.getUserInfos();
                    setTimeout(() => {
                        this.setState({success: '', error: '', redirect: true})
                    }, 2000);
                }
            },

            err => this.setState({isLoading: false})
        )
    }

    renderAlertSuccess = () => {
        const {success} = this.state;

        if(success !== '') {            
            return (
                <div className="alert alert-success">
                    {success}
                </div>
            );
        } else {
            return '';
        }
    }

    renderAlertError = () => {
        const {error} = this.state;

        if(error !== '') {            
            return (
                <div className="alert alert-danger">
                    {error}
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        const { redirect, user, isLoading } = this.state;

        if(redirect) {
            const profil = sessionStorage.getItem('profil');
            return <Redirect to={`/${profil}`} />
        }

        return (
            <>
                <Navbar user={this.user} />
                <Sidebar />

                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="new-membre-content" classNam="row">
                        <div className="col-md-12">
                            <div className="row new-membre bg-white">
                                <div className="col-md-12 py-2">
                                    <div className="new-membre-form-content mx-auto">
                                        <h5 className="new-membre-titre">Paramètres Compte</h5>

                                        <form data-form="multipart" onSubmit={this.onUpdateInfos}>                                    
                                            {this.state.success === '' ? this.renderAlertError() : this.renderAlertSuccess()}
                                            
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label for="colFormLabelPr">Prénom :</label>
                                                        <input 
                                                            style={{ borderRadius: '5px' }}
                                                            type="text"
                                                            className="form-control default-button"
                                                            name="prenom"
                                                            placeholder="Nouveau prénom"
                                                            value={this.state.prenom}
                                                            onChange={this.onHandleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="form-group">
                                                        <label for="colFormLabelNm">Nom :</label>
                                                        <input 
                                                            style={{ borderRadius: '5px' }}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Nouveau nom"
                                                            name="nom"
                                                            value={this.state.nom}
                                                            onChange={this.onHandleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">Username :</label>
                                                        <input 
                                                            style={{ borderRadius: '5px' }}
                                                            type="text"
                                                            className="form-control default-button"
                                                            name="username"
                                                            placeholder="Nouveau nom d'utilisateur"
                                                            value={this.state.username}
                                                            onChange={this.onHandleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">Mot de passe :</label>
                                                        <input 
                                                            style={{ borderRadius: '5px' }}
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Nouveau mot de passe"
                                                            name="password"
                                                            value={this.state.password}
                                                            onChange={this.onHandleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">Email :</label>
                                                        <input 
                                                            style={{ borderRadius: '5px' }}
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Nouveau email"
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={this.onHandleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">Téléphone :</label>
                                                        <input 
                                                            style={{ borderRadius: '5px' }}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Nouveau téléphone"
                                                            name="telephone"
                                                            value={this.state.telephone}
                                                            onChange={this.onHandleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">Avatar :</label>
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customAvatar"
                                                                name="avatar"
                                                                onChange={this.onHandleChange}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customAvatar">Chargez le nouvel avatar</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                 
                                            
                                            <div className="col-12 p-0">
                                                {
                                                    isLoading ? 
                                                        <button
                                                            type="button"
                                                            disabled
                                                            className="btn btn-lg btn-block button-green"
                                                            style={{ borderRadius: '5px' }}
                                                        >Chargement ...</button>
                                                    :   
                                                        <button
                                                            type="submit"
                                                            className="btn btn-lg btn-block button-green"
                                                            style={{ borderRadius: '5px' }}
                                                        >Mettre à jour</button>
                                                }
                                                
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ParametresCompte;