import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../Config';

import './NotificationOverview.css';

class NotificationOverview extends Component {
    constructor() {
        super();
        this.state = {
            notification: {},
        }
    }
    
    componentDidMount() {
        this.setState({notification: this.props.notification});
    }

    markAsReed = () => {
        const token = sessionStorage.getItem('token');
        const {notification} = this.state;

        Axios.get(`${url}/api/mark-as-reed?token=${token}&&notification_id=${this.state.notification.id}`).then(
            res => {
                notification.lu = 1
                this.setState({notification});
            }
        );
    }

    render() {
        const {notification} = this.state;

        return (
            <div className="notification-overview-box">
                <div className="notifications-overview-infos">
                    {
                        notification.lu === 0 
                        ?   <span onClick={this.markAsReed} className='objet'>{notification.contenu}</span>
                        :   <span className='vu bg-light py-2 px-4 rounded'>{notification.contenu}</span>
                    }                    
                    <span className="duree">{notification.created_at}</span>
                </div>
            </div>
        );
    }
}

export default NotificationOverview;