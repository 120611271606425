import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../../Config';

import './Enregistrements.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import ProtocoleOverview from '../../../components/protocole-overview/ProtocoleOverview';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class ListeEnregistrements extends Component {
    constructor() {
        super();
        this.state = {
            protocoles: [],
            isLoading: true,
            isEmptyRecords: false
        }
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');
        Axios.get(`${url}/api/protocoles?token=${token}`).then(
            res => {
                const {protocoles, count} = res.data;
                if(count == 0) {
                    this.setState({isEmptyRecords: true});
                }
                this.setState({isLoading: false, protocoles});
            }
        );
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    showEmptyRecordsAlert = () => {
        if(this.state.isEmptyRecords){
            return (
                <div className="alert alert-info m-5">
                    vous n'avez pas de nouveaux protocoles enregistrés
                </div>
            )
        } else {
            return '';
        }
    }

    render() {
        const {protocoles} = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="liste-enregistrements-content" className="row">
                        <div className="col-md-12">
                            <div className="row liste-enregistrements bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Protocoles enregistrés</span>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0 pb-5">
                                    {this.showLoader()}
                                    {this.showEmptyRecordsAlert()}

                                    {protocoles.map((protocole, index) => (
                                        <ProtocoleOverview
                                            key={index}
                                            from='secretaire'
                                            type="enregistrement"
                                            protocole={protocole}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ListeEnregistrements;