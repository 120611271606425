import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';

import './Session.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class Session extends Component {
    constructor() {
        super();
        this.state = {
            etatSession: 'passe',
            protocoles: [],
            otherProtocoles: [],
            membres: [],
            membresNotAdded: [],
            membresToAdd: [],
            protocoleToAdd: '',
            error: '',
            success: '',
            session_id: '',
            protocole_id: '',
            types: [],
            type_deliberation: '',
            fichier: null,
            protocoleOpened: '',
            deliberations: [],
            isLoading: true,
            isSendingData: false,
            isEmptyRecords: false,
            isEmptyMembreRecords: false
            // session: {}
        }
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');
        const {session_id} = this.props.match.params;
        this.setState({session_id});

        Axios.get(`${url}/api/session?token=${token}&session_id=${session_id}`).then(
            res => {
                const {etatSession, protocoles, otherProtocoles} = res.data;
                this.setState({etatSession});
                let count = res.data.nbrProtocoles
                if(count == 0) {
                    this.setState({isEmptyRecords: true});
                }
                
                Axios.get(`${url}/api/membres-actifs?token=${token}&session_id=${session_id}`).then(
                    res => {
                        const {membres, membresNotAdded, nbrMembres} = res.data;
                        if(nbrMembres == 0) {
                            this.setState({isEmptyMembreRecords: true});
                        }
                        this.setState({isLoading: false, protocoles, otherProtocoles, membres, membresNotAdded});
                    }
                );
            }
        );
    }

    getProtocoles = () => {
        const token = sessionStorage.getItem('token');
        const {session_id} = this.state;

        Axios.get(`${url}/api/session?token=${token}&session_id=${session_id}`).then(
            res => {
                const {protocoles, otherProtocoles} = res.data;
                let count = res.data.nbrProtocoles
                if(count == 0) {
                    this.setState({isEmptyRecords: true});
                }
                
                Axios.get(`${url}/api/membres-actifs?token=${token}&session_id=${session_id}`).then(
                    res => {
                        const {membres, membresNotAdded} = res.data;
                        console.log('membres avant erreur', membres);
                        this.setState({isLoading: false, protocoles, otherProtocoles, membres, membresNotAdded});
                    }
                );
            }
        );
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    showEmptyRecordsAlert = () => {
        if(this.state.isEmptyRecords){
            return (
                <div className="alert alert-info m-5">
                    Aucun protocole n'est ajouté à cette session actuellememnt !
                </div>
            )
        } else {
            return '';
        }
    }

    showEmptyMembreRecordsAlert = () => {
        if(this.state.isEmptyMembreRecords){
            return (
                <div className="alert alert-info m-5">
                    Aucun membre n'est ajouté à cette session actuellememnt !
                </div>
            )
        } else {
            return '';
        }
    }

    toggleProtocoleId = (protocole_id) => {
        const token = sessionStorage.getItem('token');
        const {session_id} = this.state;

        Axios.get(`${url}/api/getTypeDeliberations?token=${token}&session_id=${session_id}&protocole_id=${protocole_id}`).then(
            res => {
                const {types} = res.data;
                this.setState({types, protocole_id});
            }
        );
    }

    toggleProtocoleOpened = (protocole_id) => {
        const token = sessionStorage.getItem('token');
        const {session_id} = this.state;

        Axios.get(`${url}/api/deliberations?token=${token}&session_id=${session_id}&protocole_id=${protocole_id}`).then(
            res => {
                const {deliberations} = res.data;
                this.setState({protocoleOpened: protocole_id, deliberations});
            }
        );
    }

    closeProtocoleOpened = () => {
        this.setState({protocoleOpened: ''});
    }

    onHandleChange = (e) => {
        const {name, value} = e.target;
        this.setState({ error: '' })

        switch (name) {
            case 'protocoleToAdd':
                this.setState({protocoleToAdd: value})
                break;
            case 'type_deliberation':
                this.setState({type_deliberation: value})
                break;
            case 'fichier':
                this.setState({fichier: e.target.files[0]});
                break;
            case 'membresToAdd':
                let {membresToAdd} = this.state;
                membresToAdd.push(value);
                this.setState({membresToAdd});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { protocoleToAdd, session_id } = this.state;
        let error = '';
        this.setState({isSendingData: true, success: ''});

        if(protocoleToAdd === '') {
            error = 'Veuillez choisir le protocole à ajouter';
        } else {
            error = '';
        }

        if(error !== '') {
            this.setState({isSendingData: false, error})
        } else {
            this.setState({error})
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            formData.append('protocole_id', protocoleToAdd);
            formData.append('session_id', session_id);
            
            Axios.post(`${url}/api/add-protocole-to-session?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        let {error} = this.state;
                        const {errors} = res.data;
    
                        if (errors.protocole_id){ error = errors.protocole_id[0] }
                        else if(errors.session_id) { error = errors.session_id[0] }
                        else { error = '' }
                        this.setState({isSendingData: false, error});
                    } else {
                        this.getProtocoles();
                        this.setState({
                            isEmptyRecords: false,
                            protocoleToAdd: '',
                            error: '',
                            success: 'Protocole ajouté avec succès',
                            isSendingData: false,
                        });

                        setTimeout(() => {
                            this.setState({success: '', error: ''});
                        }, 5000);
                    }
                }
            );
        }
    }

    addMembreToSession = (e) => {
        e.preventDefault();
        const { membresToAdd, session_id } = this.state;
        let error = '';
        this.setState({isSendingData: true, success: ''});

        if(membresToAdd.length === 0) {
            error = 'Veuillez selectionner les membres à ajouter';
        } else {
            error = '';
        }

        if(error !== '') {
            this.setState({isSendingData: false, error})
        } else {
            this.setState({error})
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            formData.append('membres', membresToAdd);
            formData.append('session_id', session_id);
            
            Axios.post(`${url}/api/add-membres-to-session?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        let {error} = this.state;
                        const {errors} = res.data;
    
                        if (errors.membres){ error = errors.membres[0] }
                        else if(errors.session_id) { error = errors.session_id[0] }
                        else { error = '' }
                        this.setState({isSendingData: false, error});
                    } else {
                        this.getProtocoles();
                        this.setState({
                            isEmptyMembreRecords: false,
                            protocoleToAdd: '',
                            membresToAdd: [],
                            error: '',
                            success: 'Membres ajoutés avec succès',
                            isSendingData: false,
                        });

                        setTimeout(() => {
                            this.setState({success: '', error: ''});
                        }, 5000);
                    }
                }
            );
        }
    }

    sendDeliberation = (e) => {
        e.preventDefault();
        const {type_deliberation, fichier, protocole_id, session_id} = this.state;
        let {error} = this.state;
        this.setState({ isSendingData: true, success: '' });

        if(fichier === null) { error = 'Veuillez selectionner un fichier'; }
        else if(type_deliberation === '') { error = 'Veuillez chosir un type de deliberation'; }
        else { error = ''; }

        if(error !== '') {
            this.setState({ isSendingData: false, error })
        } else {
            console.log('form sent');
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            formData.append('protocole_id', protocole_id);
            formData.append('session_id', session_id);
            formData.append('type', type_deliberation);
            formData.append('valeur', fichier);
            
            Axios.post(`${url}/api/add-deliberation?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        let {error} = this.state;
                        const {errors} = res.data;

                        if(errors.protocole_id !== undefined){ error = errors.protocole_id[0] }
                        else if(errors.session_id !== undefined){ error = errors.session_id[0] }
                        else if (errors.type !== undefined){ error = errors.type[0] }
                        else if (errors.valeur !== undefined){ error = errors.valeur[0] }
                        else if (errors.access !== undefined){ error = errors.access[0] }
                        else { error = '' }
                        this.setState({ isSendingData: false, error });
                    }
                    else {
                        const {deliberation} = res.data;
                        this.setState({
                            isSendingData: false,
                            success: 'Délibération envoyé avec succès',
                            type_deliberation: '',
                            fichier: null,
                            types: []
                        });

                        setTimeout(() => {
                            this.setState({success: '', error: ''});
                        }, 5000);
                    }
                }
            );
        }
    }
    
    renderAlertError = () => {
        const {error} = this.state;
        if(error !== ''){
            return (
                <div className="alert alert-danger col-md-8 mx-auto text-center">
                    {error}
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccess = () => {
        const {success} = this.state;
        if(success !== ''){
            return (
                <div className="alert alert-success col-md-8 mx-auto text-center">
                    {success} <button className="btn btn-sm button-default btn-info" type="button" data-dismiss="modal">Fermer la boite</button>
                </div>
            )
        } else { return ''; }
    }

    renderListeDeliberations = () => {
        const {deliberations} = this.state;
        return (
            <>
                {deliberations.length == 0 ? 'Aucune délibération n\'a été ajouté ' :
                    deliberations.map((deliberation, index) => (
                        <>
                            <button key={index} className="button-blue deliberation-item" data-toggle="modal" data-target={`#viewFileModal${index}delib`}>
                                {deliberation.type}
                            </button>
                            <div className="modal fade" id={`viewFileModal${index}delib`} tabindex="-1" role="dialog" aria-labelledby="viewFileModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <embed src={`${url}/public/documents/${deliberation.valeur}`} width="770" height="500" type='application/pdf'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                }
                <button className="btn-danger deliberation-item" onClick={this.closeProtocoleOpened}>fermer</button>
            </>
        )
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const {
            types,
            etatSession,
            protocoles,
            otherProtocoles,
            protocoleOpened,
            membres,
            membresNotAdded,
            isSendingData
        } = this.state;
       
        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="liste-enregistrements-content" className="row">
                        <div className="col-md-12">
                            <div className="row liste-enregistrements bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Protocoles concernés</span>
                                        <span
                                            className="float-left bcg-blue text-center py-auto text-white ml-2 mt-1"
                                            style={{ display: 'block', width: '35px', height: '20px', borderRadius: '10px', cursor: 'pointer' }}
                                            onClick={this.goBack}
                                        ><i class="fas fa-chevron-left"></i></span>
                                        <div className="float-right">
                                            {
                                                etatSession === 'passe' ? ''
                                                :   <button
                                                        className="btn button-default button-green"
                                                        data-toggle="modal"
                                                        data-target="#addProtocoleToSessionModal"
                                                    >Ajouter un protocole</button>
                                            }
                                        </div>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0 pb-5">
                                    {/* {this.renderAlertError()} */}
                                    {this.showLoader()}
                                    {this.showEmptyRecordsAlert()}

                                    {protocoles.map((protocole, index) => (
                                        <div key={index} className="protocole-session-overview-box">
                                            <div className="protocole-session-overview-up">
                                                <img className="protocole-session-overview-img" src={`${url}/public/img/${protocole.image}`} />
                                                
                                                <div className="protocole-sessions-overview-infos">
                                                    <span className="auteur">{protocole.auteur}</span>
                                                    <span className="objet">
                                                        {protocole.reference}:
                                                    </span>
                                                    <span className="apercu">{protocole.titre}</span>
                                                    {
                                                        protocole.isRejete ? 
                                                            <button disabled className="btn button-default action btn-secondary">
                                                                Protocole rejeté
                                                            </button>
                                                        :
                                                            <button onClick={() => this.toggleProtocoleId(protocole.id)} className="btn button-default action btn-secondary" data-toggle="modal" data-target="#addDeliberationToProtocole">
                                                                Ajouter une délibération
                                                            </button>
                                                    }
                                                </div>
                                            </div>

                                            <div className="liste-deliberations">
                                                { 
                                                    protocoleOpened == protocole.id ? this.renderListeDeliberations() 
                                                    : <button
                                                            className="btn-warning deliberation-item"
                                                            onClick={() => this.toggleProtocoleOpened(protocole.id)}
                                                        >
                                                            Afficher les déliberations existantes
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-4">
                            <div className="row liste-enregistrements bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Membres concernés</span>
                                        <div className="float-right">
                                            {
                                                etatSession === 'passe' ? ''
                                                :   <button className="btn button-default button-blue" data-toggle="modal" data-target="#addMembresToSessionModal">Ajouter des membres</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 px-0 pb-5">
                                    {this.showLoader()}
                                    {this.showEmptyMembreRecordsAlert()}

                                    {membres.map((membre, index) => (
                                        <div key={index} className="protocole-session-overview-box">
                                            <img className="protocole-session-overview-img" src={`${url}/public/img/${membre.image}`} />
                                            
                                            <div className="protocole-sessions-overview-infos">
                                                <span className="objet">
                                                    {membre.nom}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="modal fade" id="addProtocoleToSessionModal" tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form onSubmit={this.handleSubmit}>
                                        { this.state.success == '' ? this.renderAlertError() : this.renderAlertSuccess() }

                                        <div className="form-row">
                                            <div className="col-8">
                                                <select
                                                    class="custom-select"
                                                    name="protocoleToAdd"
                                                    value={this.state.protocoleToAdd}
                                                    onChange={this.onHandleChange}
                                                >
                                                <option value="">Choisir le protocole</option>
                                                {
                                                    otherProtocoles.map((protocole, index) => (
                                                        <option key={index} value={protocole.id}>{protocole.reference}</option>
                                                    ))
                                                }
                                                </select>
                                            </div>
                                            <div className="col-4">
                                                {
                                                    isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                                    :   <button type="submit" className="btn btn-block button-green" style={{ borderRadius: '5px' }}>Ajouter</button>
                                                }
                                            </div>
                                            {/* <button className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Charger</button> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="addMembresToSessionModal" tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form onSubmit={this.addMembreToSession}>
                                        { this.state.success == '' ? this.renderAlertError() : this.renderAlertSuccess() }

                                        <div className="form-row">
                                            {membresNotAdded.map((membre, index) => (
                                                <div key={index} className="col-4 mb-3">
                                                    <div class="form-check">
                                                        <input className="form-check-input" onChange={this.onHandleChange} name="membresToAdd" type="checkbox" value={membre.id} id={membre.id} />
                                                        <label className="form-check-label" htmlFor={membre.id}>{membre.nom}</label>
                                                    </div>
                                                </div> 
                                            ))}                                       
                                            {/* <button className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Charger</button> */}
                                        </div>
                                        <div className="mt-3">
                                            {
                                                isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                                :   <button type="submit" className="btn btn-block button-green" style={{ borderRadius: '5px' }}>Ajouter</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="addDeliberationToProtocole" tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form onSubmit={this.sendDeliberation} data-form="multipart">
                                        { this.state.success == '' ? this.renderAlertError() : this.renderAlertSuccess() }

                                        <div className="form-row">
                                            <div className="col-6">
                                                <select
                                                    class="custom-select"
                                                    name="type_deliberation"
                                                    value={this.state.type_deliberation}
                                                    onChange={this.onHandleChange}
                                                >
                                                    <option value="">Type de déliberation</option>
                                                    {
                                                        types.map((type, index) => (
                                                            <option key={index} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-6">
                                                <div className="custom-file">
                                                    <input 
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="customDelibFile"
                                                        name="fichier"
                                                        onChange={this.onHandleChange}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customDelibFile">
                                                        {this.state.fichier == null ? 'charger le fichier' : 'Fichier chargé'}
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                                :   <button type="submit" className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Envoyer</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Session;