const initState = {
    discussions: [],
    messages: []
};

const rootReducer = (state = initState, action) => {
    if(action.type === 'INIT_DISCUSSION'){
        let listDiscussions = action.discussions;
        return {
            ...state,
            discussions: listDiscussions
        };
    }
    return state;
}

export default rootReducer;