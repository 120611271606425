import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';

import {url} from '../../Config';

import './Message.css';

class MessageForm extends Component {
    constructor() {
        super();
        this.state = {
            destinataire: '',
            objet: '',
            message: '',
            fichier: null,
            profil: '',
            error: '',
            success: '',
            sendMessageLoading: false,
        }
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        if(profil == 'chercheur'){
            const token = sessionStorage.getItem('token');
    
            Axios.get(`${url}/api/get-secretaire-email?token=${token}`).then(
                res => {
                    const {email} = res.data;
                    this.setState({destinataire: email, profil});
                }
            );
        }
    }

    getDiscussions = () => {
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/my-discussions?token=${token}`).then(
            res => {
                const {discussions, count} = res.data;
                if(count == 0) {
                    this.setState({isEmptyRecords: true});
                }
                this.setState({isLoading: false});
                this.props.initDiscussions(discussions);
            }
        );
    }

    onHandleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'destinataire':
                this.setState({destinataire: value})
                break;
            case 'objet':
                this.setState({objet: value})
                break;
            case 'message':
                this.setState({message: value})
                break;
            case 'fichier':
                this.setState({fichier: e.target.files[0]});
                break;
            default:
                break;
        }
    }

    sendMessage = (e) => {
        e.preventDefault();
        this.setState({sendMessageLoading: true});
        const {destinataire, objet, message, fichier} = this.state;
        let {error} = this.state;

        if(destinataire === '') {
            error = 'Le destinataire est obligatoire';
        } else if(objet === '') {
            error = 'L\'objet est obligatoire';
        } else if(message === '') {
            error = 'Le message est obligatoire';
        } else {
            error = '';
        }

        if(error !== '') {
            this.setState({error})
        } else {
            let formData = new FormData();
            formData.append('destinataire', destinataire);
            formData.append('objet', objet);
            formData.append('message', message);
            if(fichier !== null) { formData.append('fichier', fichier); }

            this.sendFormData('new-discussion', formData);
        }
    }

    sendFormData = (route, formData) => {
        const token = sessionStorage.getItem('token');
        
        Axios.post(`${url}/api/${route}?token=${token}`, formData).then(
            res => {
                if(res.data.status === 'error') {    
                    let {error} = this.state;
                    const {errors} = res.data;

                    if(errors.destinataire){ error = errors.destinataire[0] }
                    else if (errors.objet){ error = errors.objet[0] }
                    else if(errors.message) { error = errors.message[0] }
                    else if(errors.fichier) { error = errors.fichier[0] }
                    else { error = '' }
                    this.setState({error});
                } else {
                    this.setState({
                        sendMessageLoading: false,
                        message: '',
                        objet: '',
                        fichier: null,
                        error: '',
                        success: 'nouveau message envoyé avec suuccès'
                    });
                    this.getDiscussions();
                    
                    setTimeout(() => {
                        this.setState({success: ''});
                    }, 2000);
                }
            }
        );
    }

    renderAlertError = () => {
        const {error} = this.state;
        if(error !== ''){
            return (
                <div className="alert alert-danger col-md-8 mx-auto text-center">
                    {error}
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccess = () => {
        const {success} = this.state;
        if(success !== ''){
            return (
                <div className="alert alert-success col-md-8 mx-auto text-center">
                    {success}
                </div>
            )
        } else { return ''; }
    }

    clickOnButton = (e) => {
        e.preventDefault();
    }

    render() {
        const {profil, success} = this.state;

        return (
            <div className="message-form-box">
                {success === '' ? this.renderAlertError() : this.renderAlertSuccess()}

                <form className="message-form" onSubmit={this.sendMessage} data-form="multipart">
                    <input
                        className="message-input"
                        name="destinataire"
                        placeholder="A:"
                        value={this.state.destinataire}
                        onChange={this.onHandleChange}
                        disabled={profil === 'chercheur' ? true : false}
                    />
                    <input
                        className="message-input"
                        name="objet"
                        placeholder="Objet:"
                        value={this.state.objet}
                        onChange={this.onHandleChange}
                    />
                    <textarea rows="9"
                        className="message-textarea"
                        name="message"
                        placeholder="Votre message ici..."
                        value={this.state.message}
                        onChange={this.onHandleChange}
                    ></textarea>

                    <div className="message-button-contaner bcg-blue clearfix">
                        <button type="submit" className="submit float-left">
                            {!this.state.sendMessageLoading ? 'Envoyer' : <strong>Chargement...</strong>}
                        </button>
                        <div className="extras float-right">
                            {this.state.fichier !== null ? <span className="extras-item" style={{ color: "black" }}>Fichier join au message</span> : ''}
                            <button className="extras-item" data-toggle="modal" data-target="#uploadMessageFileModal" onClick={this.clickOnButton}>F</button>
                            {/* <button className="extras-item">G</button>
                            <button className="extras-item">I</button> */}
                        </div>
                    </div>
                </form>

                <div className="modal fade" id="uploadMessageFileModal" tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="form-row">
                                    <div className="col-8">
                                        <div className="custom-file">
                                            <input 
                                                type="file"
                                                className="custom-file-input"
                                                id="customJoinedFile"
                                                name="fichier"
                                                onChange={this.onHandleChange}
                                            />
                                            <label className="custom-file-label" htmlFor="customJoinedFile">
                                                {this.state.fichier == null ? 'charger le fichier' : 'Fichier chargé'}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn btn-block btn-info" data-dismiss="modal" style={{ borderRadius: '5px' }}>Femer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        discussions: state.discussions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        initDiscussions: discussions => {
            dispatch({ type: "INIT_DISCUSSION", discussions: discussions})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageForm);