import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../../Config';

import './Archives.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import ProtocoleOverview from '../../../components/protocole-overview/ProtocoleOverview';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class Archives extends Component {
    constructor() {
        super();
        this.state = {
            protocoles: [],
            isLoading: true,
            isEmptyRecords: false
        }
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');
        Axios.get(`${url}/api/archives?token=${token}`).then(
            res => {
                const {protocoles, count} = res.data;
                if(count == 0) {
                    this.setState({isEmptyRecords: true});
                }
                this.setState({isLoading: false, protocoles});
            }
        );
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    showEmptyRecordsAlert = () => {
        if(this.state.isEmptyRecords){
            return (
                <div className="alert alert-info m-5">
                    Il n'y a pas de soumissions actuellememnt !
                </div>
            )
        } else {
            return '';
        }
    }

    render() {
        const {protocoles} = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="archives-content" classNam="row">
                        <div className="col-md-12">
                            <div className="row archives bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix archives-filter">
                                        <div className="float-left">
                                            <div className="filter-group">
                                                <input type="text" name="search" className="search" placeholder="Rechercher" />
                                                <button className="search-button button-green">GO</button>
                                            </div>
                                        </div>
                                        <div className="float-right">
                                            <div className="filter-group">
                                                <input type="text" name="nature" className="filter" placeholder="Nature" />
                                                <button className="search-button button-green">GO</button>
                                            </div>

                                            <div className="filter-group">
                                                <input type="text" name="periode" className="filter" placeholder="Période" />
                                                <button className="search-button button-green">GO</button>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0 pb-5">
                                    {this.showLoader()}
                                    {this.showEmptyRecordsAlert()}

                                    {protocoles.map((protocole, index) => (
                                        <ProtocoleOverview key={index} from='secretaire' protocole={protocole} type={protocole.type} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Archives;