import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';

import {url} from '../../../Config';
import Chat from '../../../components/chat/Chat';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

import './Protocoles.css';

class ModifierProtocole extends Component {
    constructor() {
        super();
        this.state = {
            soumission_id: '',
            prenom: '',
            nom: '',
            email: '',
            telephone: '',
            fonction: '',
            institution: '',
            titre: '',
            resume: '',
            thematiques: [],
            typesRecherche: [],
            budget: '',
            debut: '',
            fin: '',
            duree: '',
            site: '',
            cibles: '',
            nomService: '',
            telephoneService: '',
            emailService: '',
            nomSponsor: '',
            telephoneSponsor: '',
            emailSponsor: '',
            doc: '',
            error: '',
            success: '',
            soumission_id: null,
            isEtapeOneValidated: false,
            listeThematiques: [],
            listeTypes: [],
            isEtapeTwoValidated: false,
            docs: [],
            isEtapeThreeValidated: false,
            isLoading: false,
            isSendingData: false,
            docId: null,
            docType: null,
        };
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'chercheur'){ this.props.history.goBack(); }
        
        const {soumission_id} = this.props.match.params;
        this.setState({soumission_id});
        this.getThematiquesAndTypesRecherche();
        this.getUserInfo();
        this.getSynopsisInfos();
        this.getSoumissionDocs();
    }

    setDocId = (id, type) => {
        this.setState({docId: id, docType: type});
    }

    onHandleChange = (e) => {
        const {name, value} = e.target;
        this.setState({error: ''});

        switch (name) {
            case 'fonction':
                this.setState({fonction: value})
                break;
            case 'institution':
                this.setState({institution: value})
                break;
            case 'titre':
                this.setState({titre: value})
                break;
            case 'resume':
                this.setState({resume: value})
                break;
            case 'thematiques':
                let {thematiques} = this.state;
                let intValue = value * 1;
                if(!thematiques.includes(intValue)) {thematiques.push(intValue) }
                else { thematiques.pop(intValue) }
                this.setState({thematiques});
                break;
            case 'typesRecherche':
                let {typesRecherche} = this.state;
                let intTypeValue = value * 1;
                if(!typesRecherche.includes(intTypeValue)) {typesRecherche.push(intTypeValue) }
                else { typesRecherche.pop(intTypeValue) }
                this.setState({typesRecherche});
                break;
            case 'budget':
                this.setState({budget: value})
                break;
            case 'debut':
                this.setState({debut: value})
                break;
            case 'fin':
                this.setState({fin: value})
                break;
            case 'duree':
                this.setState({duree: value})
                break;
            case 'site':
                this.setState({site: value})
                break;
            case 'cibles':
                this.setState({cibles: value})
                break;
            case 'nomService':
                this.setState({nomService: value})
                break;
            case 'telephoneService':
                this.setState({telephoneService: value})
                break;
            case 'emailService':
                this.setState({emailService: value})
                break;
            case 'nomSponsor':
                this.setState({nomSponsor: value})
                break;
            case 'telephoneSponsor':
                this.setState({telephoneSponsor: value})
                break;
            case 'emailSponsor':
                this.setState({emailSponsor: value})
                break;
            case 'doc':
                this.setState({doc: e.target.files[0]});
                break;
            default:
                break;
        }
    }

    getThematiquesAndTypesRecherche = () => {
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/thematiques?token=${token}`).then(
            res => {
                const listeThematiques = res.data.thematiques;
                this.setState({listeThematiques});
            }
        );

        Axios.get(`${url}/api/typesRecherche?token=${token}`).then(
            res => {
                const listeTypes = res.data.types;
                this.setState({listeTypes});
            }
        )
    }

    getUserInfo = () => {
        const token = sessionStorage.getItem('token');
        const username = sessionStorage.getItem('username');

        Axios.get(`${url}/api/user?token=${token}&username=${username}`).then(
            res => {
                const {user} = res.data;
                let email = '', telephone = '';
                user.contacts.forEach(contact => {
                    if(contact.type === 'telephone') {telephone = contact.valeur}
                    else if(contact.type === 'email') {email = contact.valeur}
                });
                this.setState({
                    prenom: user.prenom,
                    nom: user.nom,
                    email: email,
                    telephone: telephone,
                    fonction: user.fonction,
                    institution: user.institution,
                });
            }
        )
    }

    getSynopsisInfos = () => {
        const token = sessionStorage.getItem('token');
        const {soumission_id} = this.props.match.params;

        Axios.get(`${url}/api/get-synopsis-data?token=${token}&soumission_id=${soumission_id}`).then(
            res => {
                const {synopsis, thematiques, typesRecherches, service, sponsor} = res.data;
                console.log('temtiques recuperes', thematiques);
                this.setState({
                    thematiques: thematiques,
                    typesRecherche: typesRecherches,
                    nomService: service.nom,
                    telephoneService: service.telephone,
                    emailService: service.email,
                    nomSponsor: sponsor.nom,
                    telephoneSponsor: sponsor.telephone,
                    emailSponsor: sponsor.email,
                    titre: synopsis.titre,
                    resume: synopsis.resume,
                    budget: synopsis.budget,
                    debut: synopsis.debut,
                    fin: synopsis.fin,
                    duree: synopsis.duree,
                    site: synopsis.site,
                    cibles: synopsis.cibles,
                });
            }
        )
    }

    getSoumissionDocs = () => {
        const token = sessionStorage.getItem('token');
        const {soumission_id} = this.props.match.params;

        Axios.get(`${url}/api/get-soumission-docs?token=${token}&soumission_id=${soumission_id}`).then(
            res => {
                const {docs} = res.data;
                this.setState({docs});
            }
        )
    }

    sendEtape1 = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const token = sessionStorage.getItem('token');
        const {fonction, institution} = this.state;
        
        let formData = new FormData();
        if(fonction !== ''){ formData.append('fonction', fonction); }
        if(institution !== '') { formData.append('institution', institution); }
   
        Axios.post(`${url}/api/edit-etapeOne-soumission?token=${token}`, formData).then(
            res => {
                this.setState({
                    isLoading: false,
                    error: ''
                });
                this.setState({success: "Mis à jour effectué avec succès"})
                this.getUserInfo();

                setTimeout(() => {
                    this.setState({success: ''});
                }, 3000);
            }
        )
    }

    sendEtape2 = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const token = sessionStorage.getItem('token');
        const {
            soumission_id,
            titre,
            resume,
            thematiques,
            typesRecherche,
            budget,
            debut,
            fin,
            duree,
            site,
            cibles,
            nomService,
            telephoneService,
            emailService,
            nomSponsor,
            telephoneSponsor,
            emailSponsor,
        } = this.state;
        
        let formData = new FormData();
        formData.append('soumission_id', soumission_id);

        if(titre !== ''){ formData.append('titre', titre); }
        if(resume !== '') { formData.append('resume', resume); }
        if(thematiques.length > 0) { formData.append('thematiques', thematiques); }
        if(typesRecherche.length > 0) { formData.append('typesRecherche', typesRecherche); }
        if(budget !== '') { formData.append('budget', budget); }
        if(debut !== '') { formData.append('debut', debut); }
        if(fin !== '') { formData.append('fin', fin); }
        if(duree !== '') { formData.append('duree', duree); }
        if(site !== '') { formData.append('site', site); }
        if(cibles !== '') { formData.append('cibles', cibles); }
        if(nomService !== '') { formData.append('nomService', nomService); }
        if(telephoneService !== '') { formData.append('telephoneService', telephoneService); }
        if(emailService !== '') { formData.append('emailService', emailService); }
        if(nomSponsor !== '') { formData.append('nomSponsor', nomSponsor); }
        if(telephoneSponsor !== '') { formData.append('telephoneSponsor', telephoneSponsor); }
        if(emailSponsor !== '') { formData.append('emailSponsor', emailSponsor); }
   
        Axios.post(`${url}/api/edit-etapeTwo-soumission?token=${token}`, formData).then(
            res => {
                this.setState({
                    isLoading: false,
                    error: ''
                });
                this.setState({success: "Mis à jour effectué avec succès"})
                this.getSynopsisInfos();

                setTimeout(() => {
                    this.setState({success: ''});
                }, 3000);
            }
        )
    }

    renderEtapeOne = () => {
        return (
            <div className="col-md-12 mt-4 mb-5 collapse show" id="collapseEtape1" data-parent="#accordionEtapes">
                <form className="etape-one-form mx-auto" onSubmit={this.sendEtape1}>
                    {this.renderAlertSuccess()}
                    <div className="row mb-2">
                        <div className="col pr-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Prénom"
                                name="prenom"
                                value={this.state.prenom}
                                disabled
                            />
                        </div>
                        <div className="col pl-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nom"
                                name="nom"
                                value={this.state.nom}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col pr-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Téléphone"
                                name="telephone"
                                value={this.state.telephone}
                                disabled
                            />
                        </div>
                        <div className="col pl-5">
                            <input
                                type="mail"
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={this.state.email}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col pr-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Institution"
                                name="institution"
                                value={this.state.institution}
                                onChange={this.onHandleChange}
                            />
                        </div>
                        <div className="col pl-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Fonction"
                                name="fonction"
                                value={this.state.fonction}
                                onChange={this.onHandleChange}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="mt-3 btn btn-block button-green"
                        style={{ borderRadius: '5px' }}
                    >{this.state.isLoading ? "Chargement" : "Mettre à jour"}</button>
                </form>
            </div>
        );
    }

    renderEtapeTwo = () => {
        const {listeThematiques, listeTypes} = this.state;
        return (
            <div className="col-md-12 px-4 mb-3 collapse" id="collapseEtape2" data-parent="#accordionEtapes">
                <form className="etape-two-form mx-auto" onSubmit={this.sendEtape2}>
                    {this.renderAlertSuccess()}
                    <input
                        type="text"
                        className="form-control intitule"
                        name="titre"
                        value={this.state.titre}
                        onChange={this.onHandleChange}
                        placeholder="Intitulé du projet"
                    />
                {/* Left side of the form ...  */}
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <div className="form-block">
                                <span className="libelle-block black-color">Domaines/Thématiques</span>
                                <div className="row">
                                    {
                                        listeThematiques.map((thematique, index) => (
                                            <div className="col-6" key={index}>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        name="thematiques"
                                                        className="form-check-input"
                                                        id={`thematique${thematique.id}`}
                                                        value={thematique.id}
                                                        onChange={this.onHandleChange}
                                                        checked={this.state.thematiques.includes(thematique.id)}
                                                    />
                                                    <label className="form-check-label text-secondary" htmlFor={`thematique${thematique.id}`}>
                                                        {thematique.nom}
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="form-block">
                                <span className="libelle-block black-color">Types de recherche</span>
                                <div className="row">
                                    {
                                        listeTypes.map((type, index) => (
                                            <div className="col-6" key={index}>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        name="typesRecherche"
                                                        className="form-check-input"
                                                        id={`type${type.id}`}
                                                        value={type.id}
                                                        onChange={this.onHandleChange}
                                                        checked={this.state.typesRecherche.includes(type.id)}
                                                    />
                                                    <label className="form-check-label text-secondary" htmlFor={`type${type.id}`}>
                                                        {type.nom}
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="form-block">
                                <span className="libelle-block black-color">Résumé du projet</span>
                                <div className="row">
                                    <div className="col-11">
                                        <textarea
                                            className="form-control"
                                            name="resume"
                                            value={this.state.resume}
                                            onChange={this.onHandleChange}
                                            rows="5"
                                            cols="5"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    {/* Right side of the form ...  */}
                        <div className="col-md-6 pl-0">
                            <div className="form-block">
                                <span className="libelle-block black-color">Service administrateur</span>
                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nomService"
                                            value={this.state.nomService}
                                            onChange={this.onHandleChange}
                                            placeholder="Nom"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="budget"
                                            value={this.state.budget}
                                            onChange={this.onHandleChange}
                                            placeholder="Budget en CFA"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="telephoneService"
                                            value={this.state.telephoneService}
                                            onChange={this.onHandleChange}
                                            placeholder="Téléphone"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="debut"
                                            value={this.state.debut}
                                            onChange={this.onHandleChange}
                                            placeholder="Date de démarage"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="emailService"
                                            value={this.state.emailService}
                                            onChange={this.onHandleChange}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="fin"
                                            value={this.state.fin}
                                            onChange={this.onHandleChange}
                                            placeholder="Date de fin du protocole"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-block mt-4">
                                <span className="libelle-block black-color">Sponsor/Promoteur</span>
                                <div className="row mb-3 mt-2">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nomSponsor"
                                            value={this.state.nomSponsor}
                                            onChange={this.onHandleChange}
                                            placeholder="Nom"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="duree"
                                            value={this.state.duree}
                                            onChange={this.onHandleChange}
                                            placeholder="Durée estimée en mois"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="telephoneSponsor"
                                            value={this.state.telephoneSponsor}
                                            onChange={this.onHandleChange}
                                            placeholder="Téléphone"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="site"
                                            value={this.state.site}
                                            onChange={this.onHandleChange}
                                            placeholder="Site de mise en oeuvre"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="emailSponsor"
                                            value={this.state.emailSponsor}
                                            onChange={this.onHandleChange}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="cibles"
                                            value={this.state.cibles}
                                            onChange={this.onHandleChange}
                                            placeholder="Populations cibles"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <button
                        type="submit"
                        className="mt-3 btn btn-block button-green"
                        style={{ borderRadius: '5px' }}
                    >{this.state.isLoading ? "Chargement" : "Mettre à jour"}</button>
                </form>
            </div>
        );
    }

    renderEtapeThree = () => {
        const { isSendingData } = this.state;

        let types = [
            'protocole-initiale',
            'demande',
            'budget',
            'lettre-d-information',
            'formulaire-de-consentement',
            'recu',
            'lettre',
            'cv',
            'autre'
        ];

        let protocole = null;
        let demande = null;
        let recu = null;
        let budget = null;
        let lettre_d_information = null
        let formulaire_de_consentement = null;
        let cv = null;
        let lettre = null;
        let autre = null;

        return (
            <div className="col-md-12 mt-3 mb-5 collapse" id="collapseEtape3" data-parent="#accordionEtapes">
                <div className="etape-three-form mx-auto">
                    {
                        this.state.docs.map((doc, index) => {
                            if(doc.type === 'protocole-initiale'){ protocole = doc }
                            else if(doc.type === 'demande'){ demande = doc }
                            else if(doc.type === 'budget'){ budget = doc }
                            else if(doc.type === 'lettre-d-information'){ lettre_d_information = doc }
                            else if(doc.type === 'formulaire-de-consentement'){ formulaire_de_consentement = doc }
                            else if(doc.type === 'recu'){ recu = doc }
                            else if(doc.type === 'cv'){ cv = doc }
                            else if(doc.type === 'lettre'){ lettre = doc }
                            else if(doc.type === 'autre'){ autre = doc }

                            return (
                                <div key={index} className="doc">
                                    <div className="doc-type">
                                        <span>{ doc.type === 'protocole-initiale' ? 'Protocole' : doc.slug }</span>
                                    </div>

                                    <div style={{ cursor: 'default', textTransform: 'none' }} className="doc-value bcg-blue text-white clearfix">
                                        <span style={{ cursor: 'pointer', textTransform: 'uppercase' }} className="float-left" data-toggle="modal" data-target={`#viewFileModal${index}modSoum`}>
                                            <span data-toggle="tooltip" data-placement="top" title="Voir"><i class="far fa-eye"></i></span>
                                        </span>

                                        <span
                                            style={{ cursor: 'pointer'}}
                                            className="float-right edit-button bg-white blue-color"
                                            data-toggle="modal"
                                            data-target={`#edit${doc.type}`}
                                            onClick={() => this.setDocId(doc.id, doc.type)}
                                        >
                                            <span data-toggle="tooltip" data-placement="top" title="Modifier"><i class="fas fa-pencil-alt"></i></span>
                                        </span>
                                    </div>

                                    <div className="modal fade" id={`viewFileModal${index}modSoum`} tabindex="-1" role="dialog" aria-labelledby="viewFileModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <embed src={`${url}/public/documents/${doc.valeur}`} width="770" height="500" type='application/pdf'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id={`edit${doc.type}`} tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    {this.state.success === '' ? this.renderAlertError() : this.renderAlertSuccessAddDoc()}
                                                    <form data-form="multipart" onSubmit={this.editDoc}>
                                                        <div className="custom-file">
                                                            <input 
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                name="doc"
                                                                onChange={this.onHandleChange}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customFile">
                                                                {this.state.doc == '' ? 'Veuillez charger le fichier' : 'Fichier chargé'}
                                                            </label>
                                                
                                                            {
                                                                isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                                                :   <button type="submit" className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Valider la modification</button>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="doc">
                        <div className="doc-type">
                            CV des chercheurs
                        </div>
                        <button className="button-blue upload-file" data-toggle="modal" data-target="#typecv">+</button>
                    </div>

                    <div className="doc">
                        <div className="doc-type">
                            Lettres d'engagement des MCR et MCD
                        </div>
                        <button className="button-blue upload-file" data-toggle="modal" data-target="#typelettre">+</button>
                    </div>

                    <div className="doc">
                        <div className="doc-type">
                            Autre type de document
                        </div>
                        <button className="button-blue upload-file" data-toggle="modal" data-target="#typeautre">+</button>
                    </div>
                </div>

                {/* Upload doc form-modal ...  */}
                {
                    types.map((type, index) => (
                        <div key={index} className="modal fade" id={`type${type}`} tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        {this.state.success === '' ? this.renderAlertError() : this.renderAlertSuccessAddDoc()}
                                        <form data-form="multipart" onSubmit={(Event) => this.addDoc(Event, type)}>
                                            <div className="custom-file">
                                                <input 
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="customFile"
                                                    name="doc"
                                                    onChange={this.onHandleChange}
                                                />
                                                <label className="custom-file-label" htmlFor="customFile">
                                                    {this.state.doc == '' ? 'Veuillez charger le fichier' : 'Fichier chargé'}
                                                </label>
                                                
                                                {
                                                    isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                                    :   <button type="submit" className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Valider</button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }

    addDoc = (e, type) => {
        e.preventDefault();
        this.setState({isSendingData: true, success: ''});

        const {doc, soumission_id} = this.state;
        let {error} = this.state;

        if(doc === '') { error = 'Veuillez selectionner un fichier'; }
        else { error = ''; }

        if(error !== '') {
            this.setState({isSendingData: false, error})
        } else {
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            formData.append('soumission_id', soumission_id);
            formData.append('type', type);
            formData.append('valeur', doc);
            
            Axios.post(`${url}/api/add-doc?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        let {error} = this.state;
                        const {errors} = res.data;

                        if(errors.soumission_id){ error = errors.soumission_id[0] }
                        else if (errors.type){ error = errors.type[0] }
                        else if (errors.valeur){ error = errors.valeur[0] }
                        else { error = '' }
                        this.setState({isSendingData: false, error});
                    } else {
                        this.setState({isSendingData: false, success: "le fichier est chargé avec succès", doc: ''});
                        this.getSoumissionDocs();

                        setTimeout(() => {
                            this.setState({success: '', error: ''});
                        }, 4000);
                    }
                }
            );
        }
    }

    editDoc = (e) => {
        e.preventDefault();
        this.setState({isSendingData: true, success: ''});

        const {doc, docId, docType, soumission_id} = this.state;
        const token = sessionStorage.getItem('token');

        let formData = new FormData();
        formData.append('doc_id', docId);
        formData.append('doc_type', docType);
        formData.append('soumission_id', soumission_id);
        if(doc !== ''){ formData.append('doc', doc); }
        
        Axios.post(`${url}/api/edit-doc?token=${token}`, formData).then(
            res => {
                this.setState({isSendingData: false, success: "mise à jour effectuée avec succès", doc: ''});
                this.getSoumissionDocs();

                setTimeout(() => {
                    this.setState({success: '', error: ''});
                }, 4000);
            }
        );
    }

    renderAlertError = () => {
        const {error} = this.state;
        if(error !== ''){
            return (
                <div className="alert alert-danger col-md-8 mx-auto text-center">
                    {error}
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccess = () => {
        const {success} = this.state;
        if(success !== ''){
            return (
                <div className="alert alert-success col-md-8 mx-auto text-center">
                    {success}
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccessAddDoc = () => {
        const {success} = this.state;
        if(success !== ''){
            return (
                <div className="alert alert-success col-md-8 mx-auto text-center">
                    {success} <button className="btn btn-sm button-default btn-info" type="button" data-dismiss="modal">Fermer la boite</button>
                </div>
            )
        } else { return ''; }
    }

    render() {
        // const {soumissions, protocoles} = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5 py-4">
                    <div id="new-protocole-content" className="row">
                        <div className="col-md-12">
                            <div className="row new-protocole bg-white accordion" id="accordionEtapes">
                                <div className="col-md-12 mb-4 border-bottom px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Nouveau protocole</span>
                                        <span className="float-right titre text-secondary">
                                            {/* {!isEtapeOneValidated ? 'Informations personnelles' : !isEtapeTwoValidated ? 'Informations du protocoles' : 'Fichiers complémentaires'} */}
                                        </span>
                                    </div>                                
                                </div>

                                <div className="col-md-2 offset-3 pb-3">
                                    <div className="etape-indicator mx-auto" >
                                        <span className="text blue-color"
                                            style={{ cursor: 'pointer' }}
                                            data-toggle="collapse"
                                            data-target="#collapseEtape1"
                                            aria-expanded="false"
                                            aria-controls="collapseEtape1"
                                        >Etape 1</span>
                                        <div
                                            className="bar bcg-blue"
                                            style={{ cursor: 'pointer' }}
                                            data-toggle="collapse"
                                            data-target="#collapseEtape1"
                                            aria-expanded="false"
                                            aria-controls="collapseEtape1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-2 pb-3">
                                    <div className="etape-indicator mx-auto" >
                                        <span className='text blue-color'
                                            style={{ cursor: 'pointer' }}
                                            data-toggle="collapse"
                                            data-target="#collapseEtape2"
                                            aria-expanded="false"
                                            aria-controls="collapseEtape2"
                                        >Etape 2</span>
                                        <div
                                            className='bar bcg-blue'
                                            style={{ cursor: 'pointer' }}
                                            data-toggle="collapse"
                                            data-target="#collapseEtape2"
                                            aria-expanded="false"
                                            aria-controls="collapseEtape2">
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-2 pb-3">
                                    <div className="etape-indicator mx-auto" >
                                        <span className='text blue-color'
                                            style={{ cursor: 'pointer' }}
                                            data-toggle="collapse"
                                            data-target="#collapseEtape3"
                                            aria-expanded="false"
                                            aria-controls="collapseEtape3"
                                        >Etape 3</span>
                                        <div className='bar bcg-blue'
                                            style={{ cursor: 'pointer' }}
                                            data-toggle="collapse"
                                            data-target="#collapseEtape3"
                                            aria-expanded="false"
                                            aria-controls="collapseEtape3">
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-3"></div>

                                {/* {this.renderAlertError()} */}
                                {this.renderEtapeOne()} {this.renderEtapeTwo()} {this.renderEtapeThree()}

                                <div className="col-md-12 clearfix border-top py-2 pr-3">
                                    {
                                        <Link to="/chercheur/protocoles" className="btn button-default button-blue float-right">Terminer</Link>
                                    }
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ModifierProtocole;