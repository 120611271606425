import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Axios from 'axios';

import './Navbar.css';
import AuthServices from '../../services/AuthServices';

import {url} from '../../Config';

class Navbar extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            notifications: [],
            counter: 0,
            redirect: false,
            route: '',
            isLoading: false,
        };
    }

    componentDidMount(){
        const token = sessionStorage.getItem('token');
        if(token === null) { this.setState({route: '/login', redirect: true}) }
        else {
            if(this.props.user === undefined || this.props.user === null) {
                this.getUserInfos();
            } else {
                this.setState({user: this.props.user});
            }

            if(this.props.counter === undefined || this.props.counter === null) {
                this.getNotifications();
            } else {
                this.setState({counter: this.props.counter});
            }
            this.getNotifications();
        }
    }

    getUserInfos = () => {
        const token = sessionStorage.getItem('token');
        const username = sessionStorage.getItem('username');

        Axios.get(`${url}/api/user?token=${token}&&username=${username}`).then(
            res => {
                const {user} = res.data;
                this.setState({ user });
            }
        )
    }

    getNotifications = () => {
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/my-latest-notifications?token=${token}`).then(
            res => {
                const {notifications, count} = res.data;
                this.setState({notifications, counter: count});
            }
        )
    }

    logout = () => {
        const token = sessionStorage.getItem('token');
        Axios.get(`${url}/api/logout?token=${token}`).then(
            res => {
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('username');
                sessionStorage.removeItem('profil');
                this.setState({route: '/login', redirect: true});
            }
        )
    }

    render() {
        const {redirect, user, notifications, counter, route} = this.state;

        if(redirect) {
            return <Redirect to={route} />
        }

        return (
            <div id="navbar-content" className="col-md-12">
                <nav className={`navbar navbar-expand-lg px-4 py-1 shadow ${user.profil === 'secretaire' ? 'bcg-blue ' : 'bg-white'}`}>
                    <Link to={`/${user.profil}`} className="">
                        <img 
                            src={user.profil == 'secretaire' ? '/img/logo-white.png' : '/img/logo.png'}
                            style={{ maxWidth: '100px', marginRight: '60px'}} 
                            className="img-fluid"
                        />
                    </Link>

                    <ul className="ml-auto d-flex align-items-center list-unstyled mb-0">
                        <li className="nav-item dropdown mr-3">
                            <a id="notifications" href="http://example.com" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle text-gray-400 px-1">
                                <i className={`fa fa-bell ${user.profil === 'secretaire' ? 'text-white ' : 'black-color'}`}></i>
                                {
                                    counter > 0 ? <span className="notification-icon text-right"></span> : ''
                                }
                            </a>
                            <div aria-labelledby="notifications" className="dropdown-menu">
                                {
                                    counter > 0
                                    ?   <Link to="/notifications" className="dropdown-item text-center">
                                            <div className="d-flex align-items-center">
                                                <div className="text ml-2">
                                                    <p className="mb-0 blue-color">Vous avez de nouvelles notifications</p>
                                                </div>
                                            </div>
                                        </Link>
                                    :   <Link to="/notifications" className="dropdown-item text-center">
                                            <div className="d-flex align-items-center">
                                                <div className="text ml-2">
                                                    <p className="mb-0">Vous n'avez pas de nouvelles notifications</p>
                                                </div>
                                            </div>
                                        </Link>
                                }
                                <div className="dropdown-divider"></div>
                                <Link to="/notifications" className="dropdown-item text-center">
                                    <small className="font-weight-bold headings-font-family text-uppercase">
                                        Voir tous les notifications
                                    </small>
                                </Link>
                            </div>
                        </li>

                        <li className="nav-item dropdown ml-auto">
                            <a id="userInfo" href="http://example.com" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">
                                <img src={`${url}/public/img/${user.avatar}`} alt={`${user.prenom} ${user.nom}`} className="user-avatar" />
                                {/* <img src={`${url}/img/${user.avatar}`} alt={`${user.prenom} ${user.nom}`} className="user-avatar" /> */}
                                <span className={`${user.profil === 'secretaire' ? 'text-white' : 'black-color'}`} style={{ marginLeft: '10px' }}>{user.prenom} {user.nom}</span>
                            </a>
                            <div aria-labelledby="userInfo" className="dropdown-menu">
                                <Link to="/parametres" className="dropdown-item">
                                    <i className="fas fa-user-cog"></i> Parametres compte
                                </Link>
                                <div className="dropdown-divider"></div>
                                <a style={{ cursor: "pointer" }} onClick={this.logout} className="dropdown-item"><i className="fas fa-sign-out-alt"></i> Déconnexion</a>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Navbar;