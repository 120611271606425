import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    MonthView,
    Resources,
    Appointments,
    Toolbar,
    DateNavigator,
    TodayButton,
    AppointmentTooltip,
    AppointmentForm
} from '@devexpress/dx-react-scheduler-material-ui';

import './Calendrier.css';

class CalendrierOverView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resources: [
                {
                fieldName: 'location',
                title: 'Location',
                instances: [
                    { id: 'Session', text: 'Session', color: 'orange' },
                    { id: 'Avis', text: 'Avis', color: 'green' },
                    { id: 'Meeting', text: 'Meeting', color: 'pink' },
                    { id: 'Authorisation', text: 'Authorisation', color: 'blue' },
                ],
                }
            ],
        };
    }

    render() {
        const {resources} = this.state;
        const {data} = this.props;
        const today = new Date();

        return (
            <div id="calendrier-content" classNam="row">
                <div className="col-md-12">
                    <div className="row bg-white">
                        <Paper>
                            <Scheduler
                                data={data}
                            >
                                <ViewState
                                    defaultCurrentDate={today}
                                />
                                <MonthView />
                                <Toolbar />
                                <DateNavigator />
                                <TodayButton />
                                <Appointments />
                                <AppointmentTooltip
                                    showCloseButton
                                    showOpenButton
                                />
                                <Resources
                                    data={resources}
                                    // mainResourceName={mainResourceName}
                                />
                                <AppointmentForm
                                    readOnly
                                />
                            </Scheduler>
                        </Paper>
                    </div>
                </div>

                {/* <button className="add-event-button button-green">+</button> */}
            </div>
        );
    }    
}

export default CalendrierOverView;
