import React, { Component } from 'react';

import './Accueil.css';
import Chat from '../../../components/chat/Chat';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class AccueilMembre extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'membre'){ this.props.history.goBack(); }
    }

    render() {
        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5 pt-2">
                    <div id="accueil-membre-content" className="row">
                        <div className="col-md-12">
                            <Chat />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AccueilMembre;