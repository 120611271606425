import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../../Config';

import './Enregistrements.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class Enregistrements extends Component {
    constructor() {
        super();
        this.state = {
            protocole: {},
            docs: [],
            phase: '',
            isLoading: true,
            protocoleCorrige: null,
            fichiersAnnexes: [],
            typeAmendement: '',
            demande: null,
            protocoleAmende: null,
            demandeProlongement: null,
            rapportProlongement: null,
            error: '',
            success: '',
            isSendingData: false
        }
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }

        this.getProtocole();
    }

    getProtocole = () => {
        const token = sessionStorage.getItem('token');
        let id = this.props.match.params.protocole_id;

        Axios.get(`${url}/api/protocole?token=${token}&protocole_id=${id}`).then(
            res => {
                const {protocole, docs, phase} = res.data;

                this.setState({isLoading: false, protocole, docs, phase});
            }
        );
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    renderDoc = (slug, valeur, fileType, index) => {
        return (
            <>
                <div key={index} className="enregistrement-doc">
                    <div className="enregistrement-doc-type">
                        {slug}
                    </div>
                    <div className="enregistrement-doc-value bcg-blue text-white" data-toggle="modal" data-target={`#viewFileModal${index}`}>
                        <i class="far fa-eye"></i>
                    </div>
                </div>

                <div className="modal fade" id={`viewFileModal${index}`} tabindex="-1" role="dialog" aria-labelledby="viewFileModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <embed src={`${url}/public/documents/${valeur}`} width="770" height="500" type='application/pdf'/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    onHandleChange = (e) => {
        this.setState({error: ''});
        const {name, value} = e.target;

        switch (name) {
            case 'protocoleCorrige':
                this.setState({protocoleCorrige: e.target.files[0]});
                break;
            case 'fichiersAnnexes':
                this.setState({fichiersAnnexes: e.target.files});
                break;
            case 'typeAmendement':
                this.setState({typeAmendement: value});
                break;
            case 'demande':
                this.setState({demande: e.target.files[0]});
                break;
            case 'protocoleAmende':
                this.setState({protocoleAmende: e.target.files[0]});
                break;
            case 'demandeProlongement':
                this.setState({demandeProlongement: e.target.files[0]});
                break;
            case 'rapportProlongement':
                this.setState({rapportProlongement: e.target.files[0]});
                break;
            default:
                break;
        }
    }

    addProtocoleCorrige = (e) => {
        e.preventDefault();
        const { protocoleCorrige, fichiersAnnexes } = this.state;
        let error = '';
        this.setState({isSendingData: true, success: ''});

        if(protocoleCorrige === null) { error = 'Veuillez charger un fichier'; }
        else { error = ''; }

        if(error !== '') {
            this.setState({isSendingData: false, error})
        } else {
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            formData.append('protocole_id', this.state.protocole.id);
            formData.append('protocoleCorrige', protocoleCorrige);
            
            Axios.post(`${url}/api/add-protocole-corrige?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        let {error} = this.state;
                        const {errors} = res.data;

                        if(errors.protocoleCorrige){ error = errors.protocoleCorrige[0] }
                        else { error = '' }
                        this.setState({isSendingData: false, error});

                    } else {
                        if(fichiersAnnexes.length > 0){
                            Array.from(fichiersAnnexes).map(file => {
                                let formDataFile = new FormData();
                                formDataFile.append('protocole_id', this.state.protocole.id);
                                formDataFile.append('fichier', file);

                                Axios.post(`${url}/api/add-fichier-annexe?token=${token}`, formDataFile).then(
                                    resFile => {
                                        if(resFile.data.status === 'success') {
                                            this.getProtocole();
                                            this.setState({
                                                protocoleToAdd: '',
                                                protocoleCorrige: null,
                                                fichiersAnnexes: [],
                                                error: '',
                                                success: 'Fichiers ajoutés avec succès',
                                                isSendingData: false
                                            });
                                        } else {
                                            console.log('erreur', resFile.data.error);
                                            this.setState({isSendingData: false})
                                        }
                                    }
                                )
                            })
                        } else {
                            this.getProtocole();
                            this.setState({
                                protocoleToAdd: '',
                                protocoleCorrige: null,
                                error: '',
                                success: 'Protocole corrigé ajouté avec succès',
                                isSendingData: false
                            });
                        }

                        setTimeout(() => {
                            this.setState({success: ''});
                        }, 3000);
                    }
                }
            );
        }
    }

    addAmendement = (e) => {
        e.preventDefault();
        const {typeAmendement, demande, protocoleAmende} = this.state;
        let {error} = '';
        this.setState({isSendingData: true, success: ''});

        if(typeAmendement === '') { error = 'Veuillez choisir le type d\'amendement'; }
        else if(typeAmendement === 'amendement-mineur') {
            if(demande === null) { error = 'Veuillez charger la demande'; }
            else if(protocoleAmende === null) { error = 'Veuillez charger le protocole amendé'; }
            else { error = ''; }
        } else { error = ''; }

        if(error !== '') {
            this.setState({isSendingData: false, error})
        } else {
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            
            formData.append('protocole_id', this.state.protocole.id);
            formData.append('type', typeAmendement);
            if(demande !== null) { formData.append('demande', demande); }
            if(protocoleAmende !== null) { formData.append('protocoleAmende', protocoleAmende); }
            
            Axios.post(`${url}/api/add-amendement?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        let {error} = this.state;
                        const {errors} = res.data;

                        if(errors.typeAmendement){ error = errors.typeAmendement[0] }
                        else if(errors.demande){ error = errors.demande[0] }
                        else if(errors.protocoleAmende){ error = errors.protocoleAmende[0] }
                        else { error = '' }
                        this.setState({isSendingData: false, error});

                    } else {
                        this.setState({
                            protocoleToAdd: '',
                            error: '',
                            success: 'Amendement ajouté avec succès',
                            typeAmendement: '',
                            demande: null,
                            protocoleAmende: null,
                            isSendingData: false
                        });
                        this.getProtocole();

                        setTimeout(() => {
                            this.setState({success: '', error: ''});
                        }, 5000);
                    }
                }
            );
        }
    }

    addProlongement = (e) => {
        e.preventDefault();
        const {demandeProlongement, rapportProlongement} = this.state;
        let {error} = '';
        this.setState({isSendingData:true, success: ''});

        if(demandeProlongement === null) { error = 'Veuillez charger la demande'; }
        else if(rapportProlongement === null) { error = 'Veuillez charger le rapport d\'étape' }
        else { error = ''; }

        if(error !== '') {
            this.setState({isSendingData: false, error})
        } else {
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            
            formData.append('protocole_id', this.state.protocole.id);
            if(demandeProlongement !== null) { formData.append('demandeProlongement', demandeProlongement); }
            if(rapportProlongement !== null) { formData.append('rapportProlongement', rapportProlongement); }
            
            Axios.post(`${url}/api/demande-prolongement?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        let {error} = this.state;
                        const {errors} = res.data;

                        if(errors.demandeProlongement){ error = errors.demandeProlongement[0] }
                        else if(errors.rapportProlongement){ error = errors.rapportProlongement[0] }
                        else { error = '' }
                        this.setState({isSendingData: false, error});

                    } else {
                        this.setState({
                            protocoleToAdd: '',
                            error: '',
                            success: 'Demande de prolongement ajouté avec succès',
                            demandeProlongement: '',
                            rapportProlongement: null,
                            isSendingData: false, 
                        });
                        this.getProtocole();

                        setTimeout(() => {
                            this.setState({success: '', error: ''});
                        }, 5000);
                    }
                }
            );
        }
    }

    renderAlertError = () => {
        const {error} = this.state;
        if(error !== ''){
            return (
                <div className="alert alert-danger col-md-8 mx-auto text-center">
                    {error}
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccess = () => {
        const {success} = this.state;
        if(success !== ''){
            return (
                <div className="alert alert-success col-md-8 mx-auto text-center">
                    {success} <button className="btn btn-sm button-default btn-info" type="button" data-dismiss="modal">Fermer la boite</button>
                </div>
            )
        } else { return ''; }
    }

    onRejectProtocole = () => {
        const token = sessionStorage.getItem('token');
        let id = this.props.match.params.protocole_id;

        Axios.get(`${url}/api/reject-protocole?token=${token}&protocole_id=${id}`).then(
            res => {
                const { protocole } = res.data;

                this.setState({ protocole });
            }
        );
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const {protocole, docs, phase, isLoading, isSendingData} = this.state;
        let phaseActu;
        if(phase == 'enregistrement') { phaseActu = 1 }
        else if(phase == 'programmation') { phaseActu = 2 }
        else if(phase == 'etude') { phaseActu = 3 }
        else if(phase == 'correction') { phaseActu = 4 }
        else if(phase == 'avis') { phaseActu = 5 }
        else if(phase == 'autorisation') { phaseActu = 6 }
        else { phaseActu = 7 }

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="enregistrement-content" className="row">
                        <div className="col-md-8 pl-0 pr-4">
                            <div className="row enregistrement bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Protocole</span>
                                        <span
                                            className="float-left bcg-blue text-center py-auto text-white ml-2 mt-1"
                                            style={{ display: 'block', width: '35px', height: '20px', borderRadius: '10px', cursor: 'pointer' }}
                                            onClick={this.goBack}
                                        ><i class="fas fa-chevron-left"></i></span>
                                    </div>
                                </div>

                                <div className="col-md-12 px-0">
                                    {isLoading ? this.showLoader() :
                                        <div id="enregistrement-box" className="row">
                                            <div className="col-md-3 pr-0">
                                                <span className="enregistrement-label">Numéro :</span>
                                                <span className="enregistrement-label">Thème :</span>
                                                <span className="enregistrement-label">Titre :</span>
                                                <span className="enregistrement-label">Auteur :</span>
                                                <span className="enregistrement-label">Date de dépôt :</span>
                                                <span className="enregistrement-label">Date d'enregistrement :</span>
                                                <span className="enregistrement-label">Date de la session :</span>
                                            </div>
                                            <div className="col-md-9">
                                                <span className="enregistrement-input">{protocole.numero}</span>
                                                <span className="enregistrement-input">{protocole.theme}</span>
                                                <span className="enregistrement-input">{protocole.titre}</span>
                                                <span className="enregistrement-input">{protocole.auteur}</span>
                                                <span className="enregistrement-input">{protocole.dateDepot}</span>
                                                <span className="enregistrement-input">{protocole.dateEnregistrement}</span>
                                                <span className="enregistrement-input">
                                                    { protocole.dateSession ? protocole.dateSession : '' }
                                                </span>
                                            </div>

                                            <div className="col-md-12 clearfix">
                                                <span
                                                    className="float-right bcg-blue text-center py-auto text-white mr-2 mt-1"
                                                    style={{ display: 'block', width: '90px', height: '20px', borderRadius: '5px', cursor: 'pointer' }}
                                                    data-toggle="modal" data-target="#voirPlusModal"
                                                >Voir Plus</span>
                                            </div>

                                            <div className="col-md-12">
                                                { docs.map((doc, index) => (
                                                    this.renderDoc(doc.slug, doc.valeur, "PDF", index)
                                                ))}
                                            </div>
                                        </div>
                                    }

                                    {
                                        protocole.rejete ?
                                            <div className="col-md-12 py-3 pr-4 clearfix">
                                                <button className="btn button-default btn-secondary float-right ml-2">Ce protocole a été rejeté</button>
                                            </div>
                                        :
                                            <div className="col-md-12 py-3 pr-4 clearfix">
                                                {
                                                    phase == 'etude' ? 
                                                        <button
                                                            className="btn button-default btn-danger float-right ml-2"
                                                            onClick={this.onRejectProtocole}
                                                        >Rejeter le protocole</button>
                                                    :   ''
                                                }

                                                {
                                                    phase == 'correction' ? <button className="btn button-default button-green float-right ml-2" data-toggle="modal" data-target="#addProtocoleCorrected">Ajouter Correction</button>
                                                    : phase == 'avis' ? <button className="btn button-default button-green float-right ml-2" data-toggle="modal" data-target="#addProtocoleCorrected">Modifier le protocole</button>
                                                    :   ''
                                                }

                                                {
                                                    protocole.archived === 1 && phase === 'certification' ?
                                                        <>
                                                            <button
                                                                className="btn button-default button-green float-right ml-2"
                                                                data-toggle="modal" data-target="#addAmendementModal"
                                                            >Ajouter un amendement</button>

                                                            {
                                                                protocole.allowProlongement ?
                                                                <button
                                                                    className="btn button-default button-green float-right ml-2"
                                                                    data-toggle="modal" data-target="#addProlongementModal"
                                                                >Demander un prolongement</button>
                                                                :   ''
                                                            }
                                                            
                                                        </>
                                                    : ''
                                                }
                                                
                                                {/* <button className="btn button-default button-blue float-right">Enregistrer</button> */}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pl-3">
                            <div className="row progress-side bg-white">
                                <div className="col-md-12 px-3 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Etat</span>
                                    </div>
                                </div>

                                <div className="col-md-12 pb-5">
                                    {isLoading ? '' :
                                        <div id="progress-side-box" className="row">
                                            <ul>
                                                <li className="progress-side-item">
                                                    <span className={`state-marker ${phaseActu > 1 ? 'bcg-green' : 'bg-secondary'}`}
                                                        style={{ width: '20px', height: '20px', }}
                                                    >
                                                        {phaseActu > 1 ? 'v' : ''}
                                                    </span>
                                                    <span className="state-name text-secondary">Enregistrement</span>
                                                </li>
                                                <li className="progress-side-item">
                                                    <span className={`state-marker ${phaseActu > 2 ? 'bcg-green' : 'bg-secondary'}`}
                                                        style={{ width: '20px', height: '20px', }}
                                                    >
                                                        {phaseActu > 2 ? 'v' : ''}
                                                    </span>
                                                    <span className="state-name text-secondary">Programmation de session</span>
                                                </li>
                                                <li className="progress-side-item">
                                                    <span className={`state-marker ${phaseActu > 3 ? 'bcg-green' : 'bg-secondary'}`}
                                                        style={{ width: '20px', height: '20px', }}
                                                    >
                                                        {phaseActu > 3 ? 'v' : ''}
                                                    </span>
                                                    <span className="state-name text-secondary">Etude</span>
                                                </li>
                                                <li className="progress-side-item">
                                                    <span className={`state-marker ${phaseActu > 4 ? 'bcg-green' : 'bg-secondary'}`}
                                                        style={{ width: '20px', height: '20px', }}
                                                    >
                                                        {phaseActu > 4 ? 'v' : ''}
                                                    </span>
                                                    <span className="state-name text-secondary">Correction</span>
                                                </li>
                                                <li className="progress-side-item">
                                                    <span className={`state-marker ${phaseActu > 5 ? 'bcg-green' : 'bg-secondary'}`}
                                                        style={{ width: '20px', height: '20px', }}
                                                    >
                                                        {phaseActu > 5 ? 'v' : ''}
                                                    </span>
                                                    <span className="state-name text-secondary">Avis du comité</span>
                                                </li>
                                                <li className="progress-side-item">
                                                    <span className={`state-marker ${phaseActu > 6 ? 'bcg-green' : 'bg-secondary'}`}
                                                        style={{ width: '20px', height: '20px', }}
                                                    >
                                                        {phaseActu > 6 ? 'v' : ''}
                                                    </span>
                                                    <span className="state-name text-secondary">Autorisation</span>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="voirPlusModal" tabindex="-1" role="dialog" aria-labelledby="voirPlusModalModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="col-12 pt-3"><strong>Numéro :</strong> {protocole.numero}</div>
                                    <div className="col-12 pt-3"><strong>Titre :</strong> {protocole.titre}</div>
                                    <div className="col-12 pt-3"><strong>Auteur :</strong> {protocole.auteur}</div>
                                    <div className="col-12 pt-3"><strong>Service :</strong> {protocole.service}</div>
                                    <div className="col-12 pt-3"><strong>Sponsor :</strong> {protocole.sponsor}</div>
                                    <div className="col-12 pt-3"><strong>Thèmes :</strong> {protocole.theme}</div>
                                    <div className="col-12 pt-3"><strong>Types :</strong> {protocole.type}</div>
                                    <div className="col-12 pt-3"><strong>Budget :</strong> {protocole.budget} fcfa</div>
                                    <div className="col-12 pt-3"><strong>Site :</strong> {protocole.site}</div>
                                    <div className="col-12 pt-3"><strong>Cibles :</strong> {protocole.cibles}</div>
                                    <div className="col-12 pt-3"><strong>Date de début :</strong> {protocole.debut}</div>
                                    <div className="col-12 pt-3"><strong>Date de fin :</strong> {protocole.fin}</div>
                                    <div className="col-12 pt-3"><strong>Durée :</strong> {protocole.duree}</div>
                                    <div className="col-12 pt-3 text-justify"><strong>Résumé du projet :</strong> {protocole.resume}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="addProtocoleCorrected" tabindex="-1" role="dialog" aria-labelledby="addProtocoleCorrectedLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form data-form="multipart" onSubmit={this.addProtocoleCorrige}>
                                        { this.state.success == '' ? this.renderAlertError() : this.renderAlertSuccess() }

                                        <div className="row">
                                            <div className="col">
                                                <div className="custom-file">
                                                    <input 
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        name="protocoleCorrige"
                                                        onChange={this.onHandleChange}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        {this.state.protocoleCorrige == null ? 'chargez le protocole' : 'Protocole chargé'}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div className="custom-file">
                                                    <input 
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="customFile1"
                                                        name="fichiersAnnexes"
                                                        onChange={this.onHandleChange}
                                                        multiple
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile1">
                                                        {this.state.fichiersAnnexes == null ? 'Fichiers annexes' : 'Fichiers chargés'}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {
                                            isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                            :   <button className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Envoyer</button>
                                        }
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="addAmendementModal" tabindex="-1" role="dialog" aria-labelledby="addAmendementModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form data-form="multipart" onSubmit={this.addAmendement}>
                                        { this.state.success == '' ? this.renderAlertError() : this.renderAlertSuccess() }
                                        <div className="form-row">
                                            <div className="col-12">
                                                <select
                                                    class="custom-select"
                                                    name="typeAmendement"
                                                    value={this.state.typeAmendement}
                                                    onChange={this.onHandleChange}
                                                >
                                                <option value="">Choisir le type d'amendement</option>
                                                    <option value="amendement-mineur">Amendement Mineur</option>
                                                    <option value="amendement-majeur">Amendement Majeur</option>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            this.state.typeAmendement === 'amendement-mineur' ?
                                                <div className="form-row mt-4">
                                                    <div className="col-6">
                                                        <div className="custom-file">
                                                            <input 
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customDemandeFile"
                                                                name="demande"
                                                                onChange={this.onHandleChange}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customDemandeFile">
                                                                {this.state.demande == null ? 'chargez la demande' : 'Demande chargé'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="custom-file">
                                                            <input 
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customAmendementFile"
                                                                name="protocoleAmende"
                                                                onChange={this.onHandleChange}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customAmendementFile">
                                                                {this.state.protocoleAmende == null ? 'chargez le protocole' : 'Protocole chargé'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            : ''
                                        }

                                        {
                                            isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                            :   <button className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Valider</button>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="addProlongementModal" tabindex="-1" role="dialog" aria-labelledby="addProlongementModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form data-form="multipart" onSubmit={this.addProlongement}>
                                        { this.state.success == '' ? this.renderAlertError() : this.renderAlertSuccess() }

                                        <div className="form-row">
                                            <div className="col-12">
                                                <select class="custom-select">
                                                    <option value=''>Demande de Prolongement</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-row mt-4">
                                            <div className="col-6">
                                                <div className="custom-file">
                                                    <input 
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="demandeProlongementFile"
                                                        name="demandeProlongement"
                                                        onChange={this.onHandleChange}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customDemandeFile">
                                                        {this.state.demandeProlongement == null ? 'chargez la demande' : 'Demande chargé'}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="custom-file">
                                                    <input 
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="rapportProlongementFile"
                                                        name="rapportProlongement"
                                                        onChange={this.onHandleChange}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customAmendementFile">
                                                        {this.state.rapportProlongement == null ? 'chargez le rapport d\'étape' : 'Rapport d\'étape chargé'}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                            :   <button className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Valider</button>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Enregistrements;