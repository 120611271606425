import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../Config';

import './Auth.css';

class Signup extends Component {
    constructor() {
        super();
        this.state = {
            nom: '',
            telephone: '',
            email: '',
            username: '',
            password: '',
            error: {
                nom: null,
                telephone: null,
                email: null,
                username: null,
                password: null,
            },
            success: '',
            redirect: false,
            isLoading: false
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        let error = {
            nom: null,
            telephone: null,
            email: null,
            username: null,
            password: null,
        }
        this.setState({error});

        switch (name) {
            case 'nom':
                this.setState({nom: value});
                break;
            case 'telephone':
                this.setState({telephone: value});
                break;
            case 'email':
                this.setState({email: value});
                break;
            case 'username':
                this.setState({username: value});
                break;
            case 'password':
                this.setState({password: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {nom, telephone, email, username, password, error} = this.state;

        if(nom === '') {
            error.nom = 'Le champ nom est obligatoire';
        } else {
            error.nom = null;
        }
        if(telephone === '') {
            error.telephone = 'Le champ telephone est obligatoire';
        } else {
            error.telephone = null;
        }
        if(email === '') {
            error.email = 'Le champ email est obligatoire';
        } else {
            error.email = null;
        }
        if(username === '') {
            error.username = 'Le champ nom d\'utilisateur est obligatoire';
        } else {
            error.username = null;
        }
        if(password === '') {
            error.password = 'Le champ mot de passe est obligatoire';
        } else {
            error.password = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({isLoading: false, error})
        } else {
            this.sendFormData();
        }
    }

    sendFormData = () => {
        this.setState({success: ''});

        let nomComplet = this.state.nom.split(' ');
        let prenom = '';
        let nom = nomComplet[nomComplet.length - 1];
        for (let index = 0; index < nomComplet.length - 2; index++) {
            const element = nomComplet[index];
            prenom+= element+' '
        }
        prenom += nomComplet[nomComplet.length - 2];

        let formData = new FormData();
        formData.append('prenom', prenom);
        formData.append('nom', nom);
        formData.append('email', this.state.email);
        formData.append('telephone', this.state.telephone);
        formData.append('username', this.state.username);
        formData.append('password', this.state.password);

        Axios.post(`${url}/api/register`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;

                        if(errors.nom !== undefined) {
                            error.nom = errors.nom[0]
                        } else {error.nom = null}
                        if(errors.telephone !== undefined) {
                            error.telephone = errors.telephone[0]
                        } else {error.telephone = null}
                        if(errors.email !== undefined) {
                            error.email = errors.email[0]
                        } else {error.email = null}
                        if(errors.username !== undefined) {
                            error.username = errors.username[0]
                        } else {error.username = null}
                        if(errors.password !== undefined) {
                            error.password = errors.password[0]
                        } else {error.password = null}

                        this.setState({isLoading: false, error});
                    } else {
                        this.setState({isLoading: false, success: 'Votre compte a été créé. Un email avec le lien d\'activation du compte vous a été envoyé'});
                        setTimeout(() => {
                            this.setState({success: '', redirect: true});
                        }, 4000);
                    }
                },

                err => {
                    if(err.response.data.exception == 'Swift_TransportException') {
                        this.setState({isLoading: false, success: 'Votre compte a été créé. Un email avec le lien d\'activation du compte vous a été envoyé'});
                        setTimeout(() => {
                            this.setState({success: '', redirect: true});
                        }, 4000);
                    }
                    else {
                        console.log('rel error', err.response)
                    }

                    this.setState({isLoading: false, success: 'Votre compte a été créé. Un email avec le lien d\'activation du compte vous a été envoyé'});
                    setTimeout(() => {
                        this.setState({success: '', redirect: true});
                    }, 4000);
                }
            )
    }

    renderAlertSuccess = () => {
        const {success} = this.state;

        if(success !== ''){
            return (
                <div className="alert alert-success">
                    {success}
                </div>
            );
        } else {
            return '';
        }
    }

    renderAlertError = () => {
        const {error} = this.state;

        if(error.nom !== null) {            
            return (
                <div className="alert alert-danger">
                    {error.nom}
                </div>
            );
        }
        else if(error.telephone !== null){            
            return (
                <div className="alert alert-danger">
                    {error.telephone}
                </div>
            );
        }
        else if(error.email !== null){            
            return (
                <div className="alert alert-danger">
                    {error.email}
                </div>
            );
        }
        else if(error.username !== null){            
            return (
                <div className="alert alert-danger">
                    {error.username}
                </div>
            );
        }
        else if(error.password !== null){            
            return (
                <div className="alert alert-danger">
                    {error.password}
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        const { redirect, isLoading } = this.state;
        if(redirect) {
            return <Redirect to='/login' />
        }

        return (
            <div className="container-fluid p-0">
                <div className="col-md-12 signup-content">
                    <img className="signup-background" src="/img/background.jpg" alt="signup background"/>
                    <div className="signup-opacity"></div>
                    <div className="form-side">
                        <img src="/img/logo.png" className="signup-logo" alt="logo cners" />
                        <h5 className="signup-titre">Inscription</h5>
                        {this.state.success === '' ? this.renderAlertError() : this.renderAlertSuccess()}
                        <form className="signup-form" onSubmit={this.handleSubmit}>
                            <input
                                type="text"
                                className="signup-input"
                                placeholder="Prénom et Nom"
                                name="nom"
                                value={this.state.prenom}
                                onChange={this.handleChange}
                            />
                            <input
                                type="text"
                                className="signup-input"
                                placeholder="Téléphone"
                                name="telephone"
                                value={this.state.telephone}
                                onChange={this.handleChange}
                            />
                            <input
                                type="email"
                                className="signup-input"
                                placeholder="Email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                            <input
                                type="text"
                                className="signup-input"
                                placeholder="Nom d'utilisateur"
                                name="username"
                                value={this.state.username}
                                onChange={this.handleChange}
                            />
                            <input
                                type="password"
                                className="signup-input"
                                placeholder="Mot de passe"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                            <button type="submit" disabled={isLoading} className="button-blue signup-submit">
                                {!isLoading ? 'S\'inscrire' : <strong>Chargement...</strong>}
                            </button>
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

export default Signup;