import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';

import './Session.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import Chat from '../../../components/chat/Chat';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class newSession extends Component {
    constructor() {
        super();
        this.state = {
            objet: '',
            date: '',
            error: {
                objet: null,
                date: null,
            },
            redirect: false,
            isLoading: false
        }
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'objet':
                this.setState({objet: value});
                break;
            case 'date':
                this.setState({date: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {objet, date, error} = this.state;

        if(objet === '') {
            error.objet = 'Le champ objet de est obligatoire';
        } else { error.objet = null; }
        if(date === '') {
            error.date = 'Le champ date de est obligatoire';
        } else { error.date = null; }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({isLoading: false, error})
        } else {
            const token = sessionStorage.getItem('token');
            
            let formData = new FormData();
            formData.append('objet', this.state.objet);
            formData.append('date', this.state.date);

            Axios.post(`${url}/api/programmer-session?token=${token}`, formData).then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;

                        if(errors.objet !== undefined) {
                            error.objet = errors.objet[0]
                        } else {error.objet = ''}
                        if(errors.date !== undefined) {
                            error.date = errors.date[0]
                        } else {error.date = ''}

                        this.setState({isLoading: false, error});
                    } else {
                        this.setState({isLoading: false, redirect: true})
                    }
                },

                err => console.log('error', err)
            )
        }
    }

    renderAlertError = () => {
        const {error} = this.state;

        if(error.objet !== null) {            
            return (
                <div className="alert alert-danger">
                    {error.objet}
                </div>
            );
        }
        else if(error.date !== null) {            
            return (
                <div className="alert alert-danger">
                    {error.date}
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        const { redirect, isLoading } = this.state;
        if(redirect) {
            return <Redirect to='/secretaire/sessions' />
        }

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="new-session-content" classNam="row">
                        <div className="col-md-12">
                            <div className="row new-session bg-white">
                                <div className="col-md-12 py-2">
                                    <div className="new-session-form-content mx-auto">
                                        <h5 className="new-session-titre">Nouvelle Session</h5>
                                        {this.renderAlertError()}
                                        <form className="new-session-form" onSubmit={this.handleSubmit}>
                                            <input
                                                type="text"
                                                className="new-session-input"
                                                placeholder="Objet de la session"
                                                name="objet"
                                                value={this.state.objet}
                                                onChange={this.handleChange}
                                            />
                                            <input
                                                type="date"
                                                className="new-session-input"
                                                placeholder="Date de la session"
                                                name="date"
                                                value={this.state.date}
                                                onChange={this.handleChange}
                                            />
                                            <button type="submit" className="button-green new-session-submit">
                                                {!isLoading ? 'Ajouter' : <strong>Chargement...</strong>}
                                            </button>
                                        </form>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default newSession;