import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';

import './Membre.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class Membre extends Component {
    constructor() {
        super();
        this.state = {
            prenom: '',
            nom: '',
            telephone: '',
            email: '',
            error: {
                prenom: null,
                nom: null,
                telephone: null,
                email: null,
            },
            redirect: false,
            isLoading: false,
            success: '',
        }
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'prenom':
                this.setState({prenom: value});
                break;
            case 'nom':
                this.setState({nom: value});
                break;
            case 'telephone':
                this.setState({telephone: value});
                break;
            case 'email':
                this.setState({email: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {prenom, nom, telephone, email, error} = this.state;

        if(prenom === '') {
            error.prenom = 'Le champ prenom de est obligatoire';
        } else {
            error.prenom = null;
        }
        if(nom === '') {
            error.nom = 'Le champ nom de est obligatoire';
        } else {
            error.nom = null;
        }
        if(telephone === '') {
            error.telephone = 'Le champ telephone est obligatoire';
        } else {
            error.telephone = null;
        }
        if(email === '') {
            error.email = 'Le champ email est obligatoire';
        } else {
            error.email = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({isLoading: false, error})
        } else {
            this.sendFormData();
        }
    }

    renderAlertSuccess = () => {
        const {success} = this.state;

        if(success !== ''){            
            return (
                <div className="alert alert-success text-center">
                    {success}
                </div>
            );
        } else {
            return '';
        }
    }

    sendFormData = () => {
        const token = sessionStorage.getItem('token');
        
        let formData = new FormData();
        formData.append('prenom', this.state.prenom);
        formData.append('nom', this.state.nom);
        formData.append('email', this.state.email);
        formData.append('telephone', this.state.telephone);

        Axios.post(`${url}/api/new-membre?token=${token}`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;

                        if(errors.prenom !== undefined) {
                            error.prenom = errors.prenom[0]
                        } else {error.prenom = ''}
                        if(errors.nom !== undefined) {
                            error.nom = errors.nom[0]
                        } else {error.nom = ''}
                        if(errors.telephone !== undefined) {
                            error.telephone = errors.telephone[0]
                        } else {error.telephone = ''}
                        if(errors.email !== undefined) {
                            error.email = errors.email[0]
                        } else {error.email = ''}

                        this.setState({isLoading: false, error});
                    } else {
                        this.setState({
                            isLoading: false,
                            success: "Membre créé avec succès. Les identifiants de conexion ont été envoyé au compte mail renseigné"
                        })

                        setTimeout(() => {
                            this.setState({success: '', redirect: true})
                        }, 4000);
                    }
                },

                err => console.log('error', err)
            )
    }

    renderAlertError = () => {
        const {error} = this.state;

        if(error.prenom !== null) {            
            return (
                <div className="alert alert-danger">
                    {error.prenom}
                </div>
            );
        }
        else if(error.nom !== null) {            
            return (
                <div className="alert alert-danger">
                    {error.nom}
                </div>
            );
        }
        else if(error.telephone !== null){            
            return (
                <div className="alert alert-danger">
                    {error.telephone}
                </div>
            );
        }
        else if(error.email !== null){            
            return (
                <div className="alert alert-danger">
                    {error.email}
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        const { redirect, isLoading, success } = this.state;
        if(redirect) {
            return <Redirect to='/secretaire/comites' />
        }

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="new-membre-content" classNam="row">
                        <div className="col-md-12">
                            <div className="row new-membre bg-white">
                                <div className="col-md-12 py-2">
                                    <div className="new-membre-form-content mx-auto">
                                        <h5 className="new-membre-titre">Nouveau Membre</h5>
                                        {success !== '' ? this.renderAlertSuccess() : this.renderAlertError()}

                                        <form className="new-membre-form" onSubmit={this.handleSubmit}>
                                            <input
                                                type="text"
                                                className="new-membre-input"
                                                placeholder="Prénom"
                                                name="prenom"
                                                value={this.state.prenom}
                                                onChange={this.handleChange}
                                            />
                                            <input
                                                type="text"
                                                className="new-membre-input"
                                                placeholder="Nom"
                                                name="nom"
                                                value={this.state.nom}
                                                onChange={this.handleChange}
                                            />
                                            <input
                                                type="text"
                                                className="new-membre-input"
                                                placeholder="Téléphone"
                                                name="telephone"
                                                value={this.state.telephone}
                                                onChange={this.handleChange}
                                            />
                                            <input
                                                type="email"
                                                className="new-membre-input"
                                                placeholder="Email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            />
                                            <button type="submit" disabled={isLoading} className="button-green new-membre-submit">
                                                {!isLoading ? 'Ajouter' : <strong>Chargement...</strong>}
                                            </button>
                                        </form>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Membre;