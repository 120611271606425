import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';

import {url} from '../../../Config';

import './Protocoles.css';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class protocoleChercheur extends Component {
    constructor() {
        super();
        this.state = {
            protocole: {},
            docs: [],
            etat: '',
            isLoading: true,
        }
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'chercheur'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');
        const {protocole_id, type} = this.props.match.params;

        if(type == 'soumission'){
            Axios.get(`${url}/api/get-my-soumission?token=${token}&soumission_id=${protocole_id}`).then(
                res => {
                    const {protocole, docs, etat} = res.data;
                    this.setState({isLoading: false, protocole, docs, etat});
                }
            );
        } else {
            Axios.get(`${url}/api/get-my-protocole?token=${token}&protocole_id=${protocole_id}`).then(
                res => {
                    const {protocole, docs, etat} = res.data;
                    this.setState({isLoading: false, protocole, docs, etat});
                }
            );
        }
        
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    renderDoc = (type, valeur, fileType,index) => {
        return (
            <>
                <div key={index} className="protocole-chercheur-doc">
                    <div className="protocole-chercheur-doc-type">
                        {type}
                    </div>
                    <div className="protocole-chercheur-doc-value bcg-blue text-white" data-toggle="modal" data-target={`#viewFileModal${index}soumChe`}>
                        {fileType}
                    </div>
                </div>

                <div className="modal fade" id={`viewFileModal${index}soumChe`} tabindex="-1" role="dialog" aria-labelledby="viewFileModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <embed src={`${url}/public/documents/${valeur}`} width="770" height="500" type='application/pdf'/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const {protocole, docs, etat, isLoading} = this.state;
        let etapeActu;
        if(etat == 'enregistrement') { etapeActu = 1 }
        else if(etat == 'programmation') { etapeActu = 2 }
        else if(etat == 'etude') { etapeActu = 3 }
        else if(etat == 'correction') { etapeActu = 4 }
        else if(etat == 'avis') { etapeActu = 5 }
        else if(etat == 'autorisation') { etapeActu = 6 }
        else { etapeActu = 7 }
        
        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5 py-4">
                    <div id="protocole-chercheur-content" className="row">
                        <div className="col-md-8 pl-0 pr-4">
                            <div className="row protocole-chercheur bg-white">
                                <div className="col-md-12 px-4 py-3">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Protocole</span>
                                        <span
                                            className="float-left bcg-blue text-center py-auto text-white ml-2 mt-1"
                                            style={{ display: 'block', width: '35px', height: '20px', borderRadius: '10px', cursor: 'pointer' }}
                                            onClick={this.goBack}
                                        ><i class="fas fa-chevron-left"></i></span>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0">
                                    {isLoading ? this.showLoader() : 
                                        <div id="protocole-chercheur-box" className="row">
                                            <div className="col-md-3 pr-0">
                                                <span className="protocole-chercheur-label">Numéro :</span>
                                                <span className="protocole-chercheur-label">Thème :</span>
                                                <span className="protocole-chercheur-label">Titre :</span>
                                                {/* <span className="protocole-chercheur-label">Auteur :</span> */}
                                                <span className="protocole-chercheur-label">Date de dépôt :</span>
                                                <span className="protocole-chercheur-label">Date d'enregistrement:</span>
                                                <span className="protocole-chercheur-label">Date de la session :</span>
                                            </div>
                                            <div className="col-md-9">
                                                <span className="protocole-chercheur-input">{protocole.numero}</span>
                                                <span className="protocole-chercheur-input">{protocole.theme}</span>
                                                <span className="protocole-chercheur-input">{protocole.titre}</span>
                                                {/* <span className="protocole-chercheur-input">{protocole.auteur}</span> */}
                                                <span className="protocole-chercheur-input">{protocole.dateDepot}</span>
                                                <span className="protocole-chercheur-input">
                                                    {protocole.dateEnregistrement ? protocole.dateEnregistrement : ''}
                                                </span>
                                                <span className="protocole-chercheur-input">
                                                    {protocole.dateSession ? protocole.dateSession : ''}
                                                </span>
                                            </div>

                                            <div className="col-md-12">
                                                { docs.map((doc, index) => (
                                                    this.renderDoc(doc.type, doc.valeur, 'PDF', index)
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pl-3">
                            <div className="row chercheur-progress-side bg-white">
                                <div className="col-md-12 px-3 py-3">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Etat</span>
                                    </div>                                
                                </div>

                                <div className="col-md-12 pb-5">
                                    {isLoading ? '' :
                                        <div id="chercheur-progress-side-box" className="row">
                                            <ul>
                                                <li className={`chercheur-progress-side-item ${etapeActu == 1 ? 'running' : etapeActu > 1 ? 'success' : ''}`}>
                                                    <span className="state-name text-secondary">Enregistrement</span>
                                                </li>
                                                <li className={`chercheur-progress-side-item ${etapeActu < 2 ? '' : etapeActu == 2 ? 'running' : etapeActu > 2 ? 'success' : ''}`}>
                                                    <span className="state-name text-secondary">Programmation de session</span>
                                                </li>
                                                <li className={`chercheur-progress-side-item ${etapeActu < 3 ? '' : etapeActu == 3 ? 'running' : etapeActu > 3 ? 'success' : ''}`}>
                                                    <span className="state-name text-secondary">Etude</span>
                                                </li>
                                                <li className={`chercheur-progress-side-item ${etapeActu < 4 ? '' : etapeActu == 4 ? 'running' : etapeActu > 4 ? 'success' : ''}`}>
                                                    <span className="state-name text-secondary">Correction</span>
                                                </li>
                                                <li className={`chercheur-progress-side-item ${etapeActu < 5 ? '' : etapeActu == 5 ? 'running' : etapeActu > 5 ? 'success' : ''}`}>
                                                    <span className="state-name text-secondary">Avis du comité</span>
                                                </li>
                                                <li className={`chercheur-progress-side-item ${etapeActu < 6 ? '' : etapeActu == 6 ? 'running' : etapeActu > 6 ? 'success' : ''}`}>
                                                    <span className="state-name text-secondary">Autorisation</span>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default protocoleChercheur;