import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';

import {url} from '../../Config';

import './Chat.css';
import './ReponseForm.css';
import './Message.css';

import ListeActivites from '../liste-activites/ListeActivites';
// import ReponseForm from '../reponse/ReponseForm';
// import MessageForm from '../message/MessageForm';

class Chat extends Component {
    constructor() {
        super();
        this.state = {
            discussions: [],
            discussion: {},
            showMessageForm: true,
            isLoading: true,
            isEmptyRecords: false,
            destinataire: '',
            objet: '',
            message: '',
            profil: '',
            error: '',
            success: '',
            sendMessageLoading: false,
            messages: [],
            from: '',
            user_id: '',
            fichiers: [],
        }
    }

    componentDidMount(){
        const token = sessionStorage.getItem('token');
        const profil = sessionStorage.getItem('profil');

        Axios.get(`${url}/api/my-discussions?token=${token}`).then(
            res => {
                const {discussions, count} = res.data;
                if(count == 0) {
                    this.setState({isEmptyRecords: true});
                }
                this.setState({isLoading: false, isEmptyRecords: false, discussions});

                if(profil == 'chercheur'){
                    const token = sessionStorage.getItem('token');
            
                    Axios.get(`${url}/api/get-secretaire-email?token=${token}`).then(
                        res => {
                            const {email} = res.data;
                            this.setState({destinataire: email, profil});
                        }
                    );
                }
            }
        );
    }

    getDiscussions = () => {
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/my-discussions?token=${token}`).then(
            res => {
                const {discussions, count} = res.data;
                if(count == 0) {
                    this.setState({isEmptyRecords: true});
                }
                this.setState({isLoading: false, discussions});
            }
        );
    }

    getMessages = (discussion_id) => {
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/messages?token=${token}&discussion_id=${discussion_id}`).then(
            res => {
                const {messages, user_id} = res.data;
                this.setState({messages, user_id, showMessageForm: false});
            }
        );
    }

    onHandleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'destinataire':
                this.setState({destinataire: value})
                break;
            case 'objet':
                this.setState({objet: value})
                break;
            case 'message':
                this.setState({message: value})
                break;
            case 'fichiers':
                this.setState({fichiers: e.target.files});
                break;
            default:
                break;
        }
    }

    handleFormShown = () => {
        this.setState({showMessageForm: true});
    }

    sendMessage = (e) => {
        e.preventDefault();
        this.setState({sendMessageLoading: true});
        const {destinataire, objet, message} = this.state;
        let {error} = this.state;

        if(destinataire === '') {
            error = 'Le destinataire est obligatoire';
        } else if(objet === '') {
            error = 'L\'objet est obligatoire';
        } else {
            error = '';
        }

        if(error !== '') {
            this.setState({error, sendMessageLoading: false})
        } else {
            let formData = new FormData();
            formData.append('destinataire', destinataire);
            formData.append('objet', objet);
            if(message !== '') { formData.append('message', message); }

            this.sendFormData('new-discussion', formData);
        }
    }

    sendReponse = (e) => {
        e.preventDefault();
        this.setState({sendMessageLoading: true});
        const {message, fichiers, discussion} = this.state;
        let {error} = this.state;

        if(error !== '') {
            this.setState({error, sendMessageLoading: false})
        } else {
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            formData.append('discussion_id', discussion.discussion_id);
            if(message !== '') { formData.append('message', message); }
        
            Axios.post(`${url}/api/repondre?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {
                        let {error} = this.state;
                        const {errors} = res.data;

                        if(errors.message) { error = errors.message[0] }
                        else if(errors.fichier) { error = errors.fichier[0] }
                        else { error = '' }
                        this.setState({error, sendMessageLoading: false});
                    } else {
                        const newMessage = res.data.message;

                        if(fichiers.length > 0){
                            Array.from(fichiers).map(file => {
                                let formDataFile = new FormData();
                                formDataFile.append('message_id', newMessage.id);
                                formDataFile.append('fichier', file);

                                Axios.post(`${url}/api/join-file?token=${token}`, formDataFile).then(
                                    resMess => {
                                        setTimeout(() => {
                                            console.log("fichier ajoute avec success");
                                        }, 1000);
                                    }
                                )
                            })
                            this.setState({messages: []});
                            this.getMessages(discussion.discussion_id);
                            this.setState({
                                sendMessageLoading: false,
                                message: '',
                                fichiers: [],
                                error: '',
                            })
                            this.getDiscussions();
                        } else {
                            this.getMessages(discussion.discussion_id);
                            this.setState({
                                sendMessageLoading: false,
                                message: '',
                                fichiers: [],
                                error: '',
                            })
                            this.getDiscussions();
                        }
                    }
                }
            );
        }
    }

    sendFormData = (route, formData) => {
        const token = sessionStorage.getItem('token');
        
        Axios.post(`${url}/api/${route}?token=${token}`, formData).then(
            res => {
                if(res.data.status === 'error') {    
                    let {error} = this.state;
                    const {errors} = res.data;

                    if(errors.destinataire){ error = errors.destinataire[0] }
                    else if (errors.objet){ error = errors.objet[0] }
                    else if(errors.message) { error = errors.message[0] }
                    else { error = '' }
                    this.setState({error, sendMessageLoading: false});
                } else {
                    const newMessage = res.data.message;
                    const {fichiers} = this.state;

                    if(fichiers.length > 0){
                        Array.from(fichiers).map(file => {
                            let formDataFile = new FormData();
                            formDataFile.append('message_id', newMessage.id);
                            formDataFile.append('fichier', file);

                            Axios.post(`${url}/api/join-file?token=${token}`, formDataFile).then(
                                resMess => {
                                    setTimeout(() => {
                                        console.log("fichier ajoute avec success");
                                    }, 1000);
                                }
                            )
                        })
                        
                        this.setState({
                            sendMessageLoading: false,
                            message: '',
                            objet: '',
                            fichiers: [],
                            error: '',
                            success: 'nouveau message envoyé avec suuccès'
                        });
                        let discussions = [];
                        this.setState({discussions});
                        setTimeout(() => {
                            this.getDiscussions();
                        }, 10);
                        
                        setTimeout(() => {
                            this.setState({success: ''});
                        }, 2000);
                    } else {
                        this.setState({
                            sendMessageLoading: false,
                            message: '',
                            objet: '',
                            fichiers: [],
                            error: '',
                            success: 'nouveau message envoyé avec suuccès'
                        });
                        let discussions = [];
                        this.setState({discussions});
                        setTimeout(() => {
                            this.getDiscussions();
                        }, 10);
                        
                        setTimeout(() => {
                            this.setState({success: ''});
                        }, 2000);
                    }
                }
            }
        );
    }

    renderAlertError = () => {
        const {error} = this.state;
        if(error !== ''){
            return (
                <div className="alert alert-danger col-md-8 mx-auto text-center">
                    {error}
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccess = () => {
        const {success} = this.state;
        if(success !== ''){
            return (
                <div className="alert alert-success col-md-8 mx-auto text-center">
                    {success}
                </div>
            )
        } else { return ''; }
    }

    clickOnButton = (e) => {
        e.preventDefault();
    }

    showChat = (discussion) => {
        this.setState({showMessageForm: true});
        setTimeout(() => {
            this.setState({
                discussion
            });
            this.getMessages(discussion.discussion_id);
        }, 1);
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    showEmptyRecordsAlert = () => {
        if(this.state.isEmptyRecords){
            return (
                <div className="alert alert-info m-5">
                    Vous n'avez pas encore démarrer de conversation !
                </div>
            )
        } else {
            return '';
        }
    }

    renderDoc = (message) => {
        return (
            <div className="clearfix mb-3">
                {
                    message.fichiers.map((fichier) => (
                        <>
                            <div className={`message-doc ${message.emetteur == this.state.user_id ? 'float-right ml-2' : 'float-left mr-2'}`}>
                                <div className="message-doc-type">
                                    {fichier.type}
                                </div>
                                <div className="message-doc-value bcg-blue text-white" data-toggle="modal" data-target={`#viewFileModal${fichier.type}${fichier.id}fichier`}>
                                    {/* {fileType} */}
                                    pdf
                                </div>
                            </div>
                            <div className="modal fade" id={`viewFileModal${fichier.type}${fichier.id}fichier`} tabindex="-1" role="dialog" aria-labelledby="viewFileModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <embed src={`${url}/public/documents/${fichier.valeur}`} width="770" height="500" type='application/pdf'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
        )
    }

    renderNewMessageForm = () => {
        const {profil, success} = this.state;

        return (
            <div className="message-form-box">
                {success === '' ? this.renderAlertError() : this.renderAlertSuccess()}

                <form className="message-form" onSubmit={this.sendMessage} data-form="multipart">
                    <input
                        className="message-input"
                        name="destinataire"
                        placeholder="A:"
                        value={this.state.destinataire}
                        onChange={this.onHandleChange}
                        disabled={profil === 'chercheur' ? true : false}
                    />
                    <input
                        className="message-input"
                        name="objet"
                        placeholder="Objet:"
                        value={this.state.objet}
                        onChange={this.onHandleChange}
                    />
                    <textarea rows="9"
                        className="message-textarea"
                        name="message"
                        placeholder="Votre message ici..."
                        value={this.state.message}
                        onChange={this.onHandleChange}
                    ></textarea>

                    <div className="message-button-contaner bcg-blue clearfix">
                        <button type="submit" className="submit float-left">
                            {!this.state.sendMessageLoading ? 'Envoyer' : <strong>Chargement...</strong>}
                        </button>
                        <div className="extras float-right">
                            {this.state.fichiers.length > 0 ? <span className="extras-item" style={{ color: "black" }}>Fichiers joints au message</span> : ''}
                            <button className="extras-item" data-toggle="modal" data-target="#uploadMessageFileModal" onClick={this.clickOnButton}>F</button>
                            {/* <button className="extras-item">G</button>
                            <button className="extras-item">I</button> */}
                        </div>
                    </div>
                </form>

                <div className="modal fade" id="uploadMessageFileModal" tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="form-row">
                                    <div className="col-8">
                                        <div className="custom-file">
                                            <input 
                                                type="file"
                                                className="custom-file-input"
                                                id="customJoinedFile"
                                                name="fichiers"
                                                onChange={this.onHandleChange}
                                                multiple
                                            />
                                            <label className="custom-file-label" htmlFor="customJoinedFile">
                                                {this.state.fichiers.length <= 0 ? 'chargez le(s) fichier(s)' : 'Fichiers chargés'}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn btn-block button-green" data-dismiss="modal" style={{ borderRadius: '5px' }}>Charger</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderReponseForm = () => {
        const {messages, from, discussion, user_id, success} = this.state;

        return (
            <div>
                <div className='sender-overview bcg-black' >
                    <img className="sender-img" src={`${url}/public/img/${discussion.image}`} />
                    <div className="sender-infos">
                        <span className="auteur">
                            <strong className="mr-2" style={{ display: 'inline-block', width: '5px', height: '5px', borderRadius: '100%', backgroundColor: '#434442'}}></strong>
                            {discussion.auteur}
                        </span>
                        <span className="objet">{discussion.titre}</span>
                    </div>
                </div>

                <div className="reponse-box">
                    {success === '' ? this.renderAlertError() : this.renderAlertSuccess()}
                    <div className="discussion">
                        {messages.map((message, index) => (
                            <>
                                <p className={message.emetteur == user_id ? 'my-message-text text-right' : 'message-text'}>
                                    {message.contenu}
                                </p>
                                {
                                    message.fichiers ?
                                        this.renderDoc(message)
                                    : ''
                                }
                            </>
                            // <Message key={index} message={message} user_id={user_id} />
                        ))}
                    </div>

                    <form className="reponse-form" data-form="multipart" onSubmit={this.sendReponse}>
                        {from === 'secretaire' ? <button className="button-label" disabled>Répondre</button> : ''}                    
                        {this.renderAlertError}
                        <textarea
                            rows="4"
                            className="reponse-textarea"
                            name="message"
                            placeholder="Votre message ici..."
                            value={this.state.message}
                            onChange={this.onHandleChange}
                        ></textarea>

                        <div className="reponse-button-contaner bcg-blue clearfix">
                            <button type="submit" className="submit float-left">
                                {!this.state.sendMessageLoading ? 'Répondre' : <strong>Chargement...</strong>}
                            </button>
                            <div className="extras float-right">
                                {this.state.fichiers.length > 0 ? <span className="extras-item" style={{ color: "black" }}>Fichiers joints au message</span> : ''}
                                <button className="extras-item" data-toggle="modal" data-target="#uploadReponseFileModal" onClick={this.clickOnButton}>F</button>
                                {/* <button className="extras-item">G</button>
                                <button className="extras-item">I</button> */}
                            </div>
                        </div>
                    </form>

                    <div className="modal fade" id="uploadReponseFileModal" tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="form-row">
                                        <div className="col-8">
                                            <div className="custom-file">
                                                <input 
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="customJoinedFile"
                                                    name="fichiers"
                                                    onChange={this.onHandleChange}
                                                    multiple
                                                />
                                                <label className="custom-file-label" htmlFor="customJoinedFile">
                                                    {this.state.fichiers.length <= 0 ? 'chargez le(s) fichier(s)' : 'Fichiers chargés'}
                                                </label>
                                                {/* <button className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Charger</button> */}
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <button type="button" className="btn btn-block button-green" data-dismiss="modal" style={{ borderRadius: '5px' }}>Charger</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {showMessageForm} = this.state;
        const {discussions} = this.state;

        return (
            <div id="chat-content" classNam="row">
                <div className="col-md-12">
                    <div className="row liste-chats bg-white">
                        <div className="col-md-12 px-4 py-2">
                            <div className="clearfix">
                                <span className="float-left titre text-secondary">Mes discussions</span>
                                {!showMessageForm ? <button onClick={this.handleFormShown} className="float-right button-default button-green">Nouveau message</button> : ''}
                            </div>                                
                        </div>

                        <div className="col-md-6 px-0 pb-2 liste-discussions-side">
                            {this.showLoader()}
                            {this.showEmptyRecordsAlert()}
                            {discussions.map((discussion, index) => (
                                <ListeActivites
                                    onSelect={() => this.showChat(discussion)}
                                    key={index}
                                    from='secretaire'
                                    activite={discussion}
                                />
                            ))}
                        </div>

                        <div className="col-md-6 pr-3 pl-0 mb-4" style={{ borderLeft: '2px solid #F8F9FB' }}>
                            {showMessageForm ? this.renderNewMessageForm() : this.renderReponseForm()}                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Chat;

// export default Chat;