import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../../Config';

import './Calendrier.css';
import CalendrierOverView from '../../../components/calendrier/CalendrierOverView';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class CalendrierMembre extends Component {
    constructor() {
        super();
        this.state = {
            sessions: []
        }
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'membre'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/get-membre-session-events?token=${token}`).then(
            res => {
                const {sessions} = res.data;
                this.setState({sessions});
            }
        );
    }

    render() {
        const {sessions} = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5 pt-2">
                    <CalendrierOverView data={sessions} />
                </div>
            </>
        );
    }
}

export default CalendrierMembre;