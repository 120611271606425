import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';

import './Accueil.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import ListeActivites from '../../../components/liste-activites/ListeActivites';
import MessageForm from '../../../components/message/MessageForm';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class Accueil extends Component {
    constructor() {
        super();
        this.state = {
            activites: [],
        }
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }

        const token = sessionStorage.getItem('token');
        Axios.get(`${url}/api/get-lasts-activites?token=${token}`).then(
            res => {
                const {activites} = res.data;
                this.setState({activites});
            }
        )
    }

    render() {
        const {activites} = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="accueil-content" className="row">
                        <div className="col-md-6 activite-recent-container">
                            <div className="row activite-recent bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Activités récentes</span>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0 pb-2">
                                    {activites.map((activite, index) => (
                                        <ListeActivites key={index} from='secretaire' activite={activite}/>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="row message bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Ecrire</span>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0 pb-1">
                                    <MessageForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Accueil;