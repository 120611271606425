import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../Config';

import './MembreOverview.css';

class MembreOverview extends Component {
    constructor() {
        super();
        this.state = {
            membre: {}
        }
    }
    
    componentDidMount() {
        this.setState({membre: this.props.membre});
    }

    changeEtat = () => {
        const token = sessionStorage.getItem('token');
        
        let { membre } = this.state;
        Axios.get(`${url}/api/change-etat-membre?token=${token}&compte_id=${membre.compte_id}`).then(
            res => {
                if(res.data.status == 'success') {
                    membre.etat === 'actif' ? membre.etat = 'inactif' : membre.etat = 'actif'
                    this.setState({membre});
                } else {
                    console.log('erreur on changing compte state');
                }
            }
        );
        
    }

    render() {
        const {membre} = this.state;
        
        return (
            <div className="membre-box">
                <img className="membre-img" src={`${url}/public/img/${membre.image}`} />
                <div className="membre-infos">
                    <span className="nom">
                        <strong className="bcg-blue mr-2" style={{ display: 'inline-block', width: '5px', height: '5px', borderRadius: '100%'}}></strong>
                        {membre.nom}
                    </span>

                    <div className="status-button">
                        <label class="switch ">
                            <input
                                type="checkbox"
                                checked={membre.etat === 'actif' ? true : false}
                                class="enabled"
                                onChange={this.changeEtat}
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default MembreOverview;