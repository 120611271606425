import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../Config';

import './ListeOverviews.css';
import Overview from '../overview/Overview';

class ListeOverviews extends Component {
    constructor() {
        super();
        this.state = {
            overviews: []
        }
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        Axios.get(`${url}/api/get-overviews?token=${token}`).then(
            res => {
                const {overviews} = res.data;
                this.setState({overviews});
            }
        )
    }

    render() {
        const {overviews} = this.state;

        return (
            <div id="liste-overviews" className="col-md-12">
                <div className="row">
                    {overviews.map((overview, index) => (
                        <Overview key={index} overview={overview}/>
                    ))}
                </div>
            </div>
        );
    }
}

export default ListeOverviews;