import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';
import Chat from '../../../components/chat/Chat';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

import './Protocoles.css';

class NewProtocole extends Component {
    constructor() {
        super();
        this.state = {
            prenom: '',
            nom: '',
            email: '',
            telephone: '',
            fonction: '',
            institution: '',
            titre: '',
            resume: '',
            thematiques: [],
            typesRecherche: [],
            budget: '',
            debut: '',
            fin: '',
            duree: '',
            site: '',
            cibles: '',
            nomService: '',
            telephoneService: '',
            emailService: '',
            nomSponsor: '',
            telephoneSponsor: '',
            emailSponsor: '',
            doc: '',
            error: '',
            errorOnCancel: '',
            errorAddDoc: '',
            success: '',
            successOnCancel: '',
            isCanceled: false,
            soumission_id: null,
            isEtapeOneValidated: false,
            listeThematiques: [],
            listeTypes: [],
            isEtapeTwoValidated: false,
            docs: [],
            isEtapeThreeValidated: false,
            isLoading: false,
            isCanceling: false,
            isSendingData: false,
            typeDateStart: "text",
            typeDateEnd: "text"
        }
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'chercheur'){ this.props.history.goBack(); }
        
        this.getActualSoumission();
    }

    getActualSoumission = () => {
        const token = sessionStorage.getItem('token');
        Axios.get(`${url}/api/get-actual-soumission?token=${token}`).then(
            res => {
                if(res.data.status === "error"){
                    const username = sessionStorage.getItem('username');
                    Axios.get(`${url}/api/user?token=${token}&username=${username}`).then(
                        res => {
                            const {user} = res.data;
                            let email = '', telephone = '';
                            user.contacts.forEach(contact => {
                                if(contact.type === 'telephone') {telephone = contact.valeur}
                                else if(contact.type === 'email') {email = contact.valeur}
                            });
                            this.setState({
                                prenom: user.prenom,
                                nom: user.nom,
                                email: email,
                                telephone: telephone,
                                fonction: user.fonction,
                                institution: user.institution,
                            });
                        }
                    )
                } else {
                    const {soumission} = res.data;
                    this.setState({soumission_id: soumission.id})
                    if(soumission.etape == 2) {
                        this.getThematiquesAndTypesRecherche();
                        this.setState({isEtapeOneValidated: true});
                    }
                    else if(soumission.etape == 3) {
                        this.getSoumissionDocs();
                        this.setState({isEtapeOneValidated: true, isEtapeTwoValidated: true}); 
                    }
                }
            }
        )
    }

    onHandleChange = (e) => {
        const {name, value} = e.target;
        this.setState({error: ''});

        switch (name) {
            case 'fonction':
                this.setState({fonction: value})
                break;
            case 'institution':
                this.setState({institution: value})
                break;
            case 'titre':
                this.setState({titre: value})
                break;
            case 'resume':
                this.setState({resume: value})
                break;
            case 'thematiques':
                let {thematiques} = this.state;
                let intValue = value * 1;
                if(!thematiques.includes(intValue)) {thematiques.push(intValue) }
                else { thematiques.pop(intValue) }
                this.setState({thematiques});
                break;
            case 'typesRecherche':
                let {typesRecherche} = this.state;
                let intTypeValue = value * 1;
                if(!typesRecherche.includes(intTypeValue)) {typesRecherche.push(intTypeValue) }
                else { typesRecherche.pop(intTypeValue) }
                this.setState({typesRecherche});
                break;
            case 'budget':
                this.setState({budget: value})
                break;
            case 'debut':
                this.setState({debut: value})
                break;
            case 'fin':
                this.setState({fin: value})
                break;
            case 'duree':
                this.setState({duree: value})
                break;
            case 'site':
                this.setState({site: value})
                break;
            case 'cibles':
                this.setState({cibles: value})
                break;
            case 'nomService':
                this.setState({nomService: value})
                break;
            case 'telephoneService':
                this.setState({telephoneService: value})
                break;
            case 'emailService':
                this.setState({emailService: value})
                break;
            case 'nomSponsor':
                this.setState({nomSponsor: value})
                break;
            case 'telephoneSponsor':
                this.setState({telephoneSponsor: value})
                break;
            case 'emailSponsor':
                this.setState({emailSponsor: value})
                break;
            case 'doc':
                this.setState({doc: e.target.files[0]});
                break;
            default:
                break;
        }
    }

    onClickNext = () => {
        const token = sessionStorage.getItem('token');
        this.setState({isLoading: true});

        if(!this.state.isEtapeOneValidated) {
            this.setState({errorApi: null});
            const {prenom, nom, email, telephone, fonction, institution} = this.state;
            let {error} = this.state;

            if(institution === '' || institution === null) {
                error = 'Le champ institution est obligatoire';
            } else if(fonction === '') {
                error = 'Le champ fonction est obligatoire';
            } else {
                error = '';
            }
    
            if(error !== '') {
                this.setState({error, isLoading: false})
            } else {
                let formData = new FormData();
                formData.append('prenom', prenom);
                formData.append('nom', nom);
                formData.append('email', email);
                formData.append('telephone', telephone);
                formData.append('fonction', fonction);
                formData.append('institution', institution);
   
                Axios.post(`${url}/api/new-soumission?token=${token}`, formData).then(
                    res => {
                        if(res.data.status === 'error') {
                            let {error} = this.state;
                            const {errors} = res.data;
                            if(errors.fonction){ error = errors.fonction[0] }
                            else if (errors.institution){ error = errors.institution[0] }
                            else { error = '' }
                            this.setState({error, isLoading: false}); 
                        } else {
                            const {soumission} = res.data;
                            this.getThematiquesAndTypesRecherche();
                            this.getActualSoumission();
                            this.setState({
                                isEtapeOneValidated: true,
                                isLoading: false,
                                error: ''
                            });
                        }
                    }
                )

                // this.sendFormData('new-soumission', formData);
            }
        }
        else if(!this.state.isEtapeTwoValidated) {
            this.setState({errorApi: null});
            const {
                soumission_id,
                titre,
                resume,
                thematiques,
                typesRecherche,
                budget,
                debut,
                fin,
                duree,
                site,
                cibles,
                nomService,
                telephoneService,
                emailService,
                nomSponsor,
                telephoneSponsor,
                emailSponsor,
            } = this.state;
            let {error} = this.state;

            if(titre === '') { error = 'Le champ titre est obligatoire'; }
            else if(resume === '') { error = 'Le champ resume est obligatoire'; }
            else if(thematiques.length === 0) { error = 'Veuillez choisir au moins une thematique'; }
            else if(typesRecherche.length === 0) { error = 'Veuillez choisir au moins un type de recherche'; }
            else if(budget === '') { error = 'Le champ budget est obligatoire'; }
            else if(debut === '') { error = 'Le champ date de demarage est obligatoire'; }
            else if(fin === '') { error = 'Le champ date de fin est obligatoire'; }
            else if(duree === '') { error = 'Le champ duree estime est obligatoire'; }
            else if(site === '') { error = 'Le champ site de mise en oeuvre est obligatoire'; }
            else if(cibles === '') { error = 'Le champ populations cibles est obligatoire'; }
            else if(nomService === '') { error = 'Le nom du service administrateur est obligatoire'; }
            else if(telephoneService === '') { error = 'Le telephone du service administrateur est obligatoire'; }
            else if(emailService === '') { error = 'L\'email du service administrateur est obligatoire'; }
            else if(nomSponsor === '') { error = 'Le nom du sponsor est obligatoire'; }
            else if(telephoneSponsor === '') { error = 'Le telephone du sponsor est obligatoire'; }
            else if(emailSponsor === '') { error = 'L\'email du sponsor est obligatoire'; }
            else { error = ''; }
    
            if(error !== '') {
                this.setState({error, isLoading: false});
            } else {
                let formData = new FormData();
                formData.append('soumission_id', soumission_id);
                formData.append('titre', titre);
                formData.append('resume', resume);
                formData.append('thematiques', thematiques);
                formData.append('typesRecherche', typesRecherche);
                formData.append('budget', budget);
                formData.append('debut', debut);
                formData.append('fin', fin);
                formData.append('duree', duree);
                formData.append('site', site);
                formData.append('cibles', cibles);
                formData.append('nomService', nomService);
                formData.append('telephoneService', telephoneService);
                formData.append('emailService', emailService);
                formData.append('nomSponsor', nomSponsor);
                formData.append('telephoneSponsor', telephoneSponsor);
                formData.append('emailSponsor', emailSponsor);

                Axios.post(`${url}/api/create-synopsis?token=${token}`, formData).then(
                    res => {
                        if(res.data.status === 'error') {
                            let {error} = this.state;
                            const {errors} = res.data;

                            if(errors.titre) { error = errors.titre[0] }
                            else if(errors.thematiques) { error = errors.thematiques[0] }
                            else if(errors.typesRecherche) { error = errors.typesRecherche[0] }
                            else if(errors.budget) { error = errors.budget[0] }
                            else if(errors.debut) { error = errors.debut[0] }
                            else if(errors.fin) { error = errors.fin[0] }
                            else if(errors.duree) { error = errors.duree[0] }
                            else if(errors.site) { error = errors.site[0] }
                            else if(errors.cibles) { error = errors.cibles[0] }
                            else if(errors.nomService) { error = errors.nomService[0] }
                            else if(errors.telephoneService) { error = errors.telephoneService[0] }
                            else if(errors.emailService) { error = errors.emailService[0] }
                            else if(errors.nomSponsor) { error = errors.nomSponsor[0] }
                            else if(errors.telephoneSponsor) { error = errors.telephoneSponsor[0] }
                            else if(errors.emailSponsor) { error = errors.emailSponsor[0] }
                            else { error = '' }
                            this.setState({error, isLoading: false});
                        } else {
                            const {data} = res.data;
                            this.getSoumissionDocs();
                            this.getActualSoumission();
                            this.setState({
                                isEtapeTwoValidated: true,
                                isLoading: false,
                                error: ''
                            });
                        }
                    }
                )
            }
        }
        else {
            let formData = new FormData();
            formData.append('soumission_id', this.state.soumission_id);

            Axios.post(`${url}/api/end-soumission?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        this.setState({error: res.data.error, isLoading: false})
                        setTimeout(() => {
                            this.setState({error: ''})
                        }, 3000);
                    } else {
                        this.setState({error: '', isEtapeThreeValidated: true, isLoading: false});
                    }
                }
            )
        }
    }

    onCancelSoumission = () => {
        const token = sessionStorage.getItem('token');
        this.setState({isCanceling: true});
        
        let formData = new FormData();
        formData.append('soumission_id', this.state.soumission_id);

        Axios.post(`${url}/api/cancel-soumission?token=${token}`, formData).then(
            res => {
                if(res.data.status === 'error') {
                    let {errorOnCancel} = this.state;
                    const {errors} = res.data;

                    if(errors.soumission_id){ errorOnCancel = errors.soumission_id[0] }
                    else { errorOnCancel = '' }

                    this.setState({errorOnCancel, isCanceling: false});

                } else {
                    this.setState({
                        isCanceling: false,
                        successOnCancel: "La soumission a été annulée avec succès !"
                    });

                    setTimeout(() => {
                        let modal = document.querySelector('.modal-backdrop');
                        modal.classList.replace('show', 'hide');
                        this.setState({successOnCancel: '', isCanceled: true});
                    }, 1000);
                }
            }
        )
    }

    getThematiquesAndTypesRecherche = () => {
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/thematiques?token=${token}`).then(
            res => {
                const listeThematiques = res.data.thematiques;
                this.setState({listeThematiques});
            }
        );

        Axios.get(`${url}/api/typesRecherche?token=${token}`).then(
            res => {
                const listeTypes = res.data.types;
                this.setState({listeTypes});
            }
        )
    }

    getSoumissionDocs = () => {
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/get-soumisson-docs?token=${token}&soumission_id=${this.state.soumission_id}`).then(
            res => {
                const {docs} = res.data;
                setTimeout(() => {
                    this.setState({docs});
                }, 1000);
            }
        );
    }

    renderAlertError = () => {
        const {error} = this.state;
        if(error !== ''){
            return (
                <div className="alert alert-danger col-md-8 mx-auto text-center">
                    {error}
                </div>
            )
        } else { return ''; }
    }

    renderAlertErrorOnCancel = () => {
        const {errorOnCancel} = this.state;
        if(errorOnCancel !== ''){
            return (
                <div className="alert alert-danger col-12 text-center">
                    {errorOnCancel}
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccess = () => {
        const {success} = this.state;
        if(success !== ''){
            return (
                <div className="alert alert-success alert-dismissible col-md-8 mx-auto text-center">
                    {success} <button className="btn btn-sm button-default btn-info" type="button" data-dismiss="modal">Fermer la boite</button>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccessOnCancel = () => {
        const {successOnCancel} = this.state;
        if(successOnCancel !== ''){
            return (
                <div className="alert alert-success col-12text-center">
                    {successOnCancel} 
                </div>
            )
        } else { return ''; }
    }

    renderAlertSuccessAddDoc = () => {
        const {success} = this.state;
        if(success !== ''){
            return (
                <div className="alert alert-success col-md-8 mx-auto text-center">
                    {success} <button className="btn btn-sm button-default btn-info" type="button" data-dismiss="modal">Fermer la boite</button>
                </div>
            )
        } else { return ''; }
    }

    renderAlertErrorAddDoc = () => {
        const {errorAddDoc} = this.state;
        if(errorAddDoc !== ''){
            return (
                <div className="alert alert-danger col-md-8 mx-auto text-center">
                    {errorAddDoc}
                </div>
            )
        } else { return ''; }
    }

    renderEtapeOne = () => {
        return (
            <div className="col-md-12 mt-4 mb-5">
                <form className="etape-one-form mx-auto">
                    <div className="row mb-2">
                        <div className="col pr-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Prénom"
                                name="prenom"
                                value={this.state.prenom}
                                disabled
                            />
                        </div>
                        <div className="col pl-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nom"
                                name="nom"
                                value={this.state.nom}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col pr-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Téléphone"
                                name="telephone"
                                value={this.state.telephone}
                                disabled
                            />
                        </div>
                        <div className="col pl-5">
                            <input
                                type="mail"
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={this.state.email}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col pr-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Institution"
                                name="institution"
                                value={this.state.institution}
                                onChange={this.onHandleChange}
                            />
                        </div>
                        <div className="col pl-5">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Fonction"
                                name="fonction"
                                value={this.state.fonction}
                                onChange={this.onHandleChange}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    renderEtapeTwo = () => {
        const {listeThematiques, listeTypes} = this.state;
        return (
            <div className="col-md-12 px-4 mb-3">
                <form className="etape-two-form mx-auto">
                    <input
                        type="text"
                        className="form-control intitule"
                        name="titre"
                        value={this.state.titre}
                        onChange={this.onHandleChange}
                        placeholder="Intitulé du projet"
                    />
                {/* Left side of the form ...  */}
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <div className="form-block">
                                <span className="libelle-block black-color">Domaines/Thématiques</span>
                                <div className="row">
                                    {
                                        listeThematiques.map((thematique, index) => (
                                            <div className="col-6" key={index}>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        name="thematiques"
                                                        className="form-check-input"
                                                        id={`thematique${thematique.id}`}
                                                        value={thematique.id}
                                                        onChange={this.onHandleChange}
                                                    />
                                                    <label className="form-check-label text-secondary" htmlFor={`thematique${thematique.id}`}>
                                                        {thematique.nom}
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="form-block">
                                <span className="libelle-block black-color">Types de recherche</span>
                                <div className="row">
                                    {
                                        listeTypes.map((type, index) => (
                                            <div className="col-6" key={index}>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        name="typesRecherche"
                                                        className="form-check-input"
                                                        id={`type${type.id}`}
                                                        value={type.id}
                                                        onChange={this.onHandleChange}
                                                    />
                                                    <label className="form-check-label text-secondary" htmlFor={`type${type.id}`}>
                                                        {type.nom}
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="form-block">
                                <span className="libelle-block black-color">Résumé du projet</span>
                                <div className="row">
                                    <div className="col-11">
                                        <textarea
                                            className="form-control"
                                            name="resume"
                                            value={this.state.resume}
                                            onChange={this.onHandleChange}
                                            rows="5"
                                            cols="5"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    {/* Right side of the form ...  */}
                        <div className="col-md-6 pl-0">
                            <div className="form-block">
                                <span className="libelle-block black-color">Service administrateur</span>
                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nomService"
                                            value={this.state.nomService}
                                            onChange={this.onHandleChange}
                                            placeholder="Nom"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="budget"
                                            value={this.state.budget}
                                            onChange={this.onHandleChange}
                                            placeholder="Budget en CFA"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="telephoneService"
                                            value={this.state.telephoneService}
                                            onChange={this.onHandleChange}
                                            placeholder="Téléphone"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type={this.state.typeDateStart}
                                            className="form-control"
                                            name="debut"
                                            value={this.state.debut}
                                            onChange={this.onHandleChange}
                                            placeholder="Date de démarage"
                                            onFocus={() => {this.setState({typeDateStart: "date"})}}
                                            onBlur={() => {this.setState({typeDateStart: "text"})}}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="emailService"
                                            value={this.state.emailService}
                                            onChange={this.onHandleChange}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type={this.state.typeDateEnd}
                                            className="form-control"
                                            name="fin"
                                            value={this.state.fin}
                                            onChange={this.onHandleChange}
                                            placeholder="Date de fin du protocole"
                                            onFocus={() => {this.setState({typeDateEnd: "date"})}}
                                            onBlur={() => {this.setState({typeDateEnd: "text"})}}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-block mt-4">
                                <span className="libelle-block black-color">Sponsor/Promoteur</span>
                                <div className="row mb-3 mt-2">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nomSponsor"
                                            value={this.state.nomSponsor}
                                            onChange={this.onHandleChange}
                                            placeholder="Nom"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="duree"
                                            value={this.state.duree}
                                            onChange={this.onHandleChange}
                                            placeholder="Durée estimée en mois"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="telephoneSponsor"
                                            value={this.state.telephoneSponsor}
                                            onChange={this.onHandleChange}
                                            placeholder="Téléphone"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="site"
                                            value={this.state.site}
                                            onChange={this.onHandleChange}
                                            placeholder="Site de mise en oeuvre"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="emailSponsor"
                                            value={this.state.emailSponsor}
                                            onChange={this.onHandleChange}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="col pl-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="cibles"
                                            value={this.state.cibles}
                                            onChange={this.onHandleChange}
                                            placeholder="Populations cibles"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    addDoc = (e, type) => {
        e.preventDefault();
        this.setState({isSendingData: true, success: ''});
        const {doc, soumission_id} = this.state;
        let {errorAddDoc} = this.state;

        if(doc === '') { errorAddDoc = 'Veuillez selectionner un fichier'; }
        else { errorAddDoc = ''; }

        if(errorAddDoc !== '') {
            this.setState({isSendingData: false, errorAddDoc})
            setTimeout(() => {
                this.setState({errorAddDoc: ''});
            }, 2000);
        } else {
            const token = sessionStorage.getItem('token');

            let formData = new FormData();
            formData.append('soumission_id', soumission_id);
            formData.append('type', type);
            formData.append('valeur', doc);
            
            Axios.post(`${url}/api/add-doc?token=${token}`, formData).then(
                res => {
                    if(res.data.status === 'error') {    
                        let {errorAddDoc} = this.state;
                        const {errors} = res.data;

                        if(errors.soumission_id){ errorAddDoc = errors.soumission_id[0] }
                        else if (errors.type){ errorAddDoc = errors.type[0] }
                        else if (errors.valeur){ errorAddDoc = errors.valeur[0] }
                        else { errorAddDoc = '' }
                        this.setState({isSendingData: false, errorAddDoc});

                        setTimeout(() => {
                            this.setState({errorAddDoc: ''});
                        }, 2000);
                    } else {
                        this.getSoumissionDocs();
                        this.setState({isSendingData: false, success: "le fichier est chargé avec succès", doc: ''});

                        setTimeout(() => {
                            this.setState({success: ''});
                        }, 4000);
                    }
                }
            );
        }
    }

    editDoc = (e, docId, type) => {
        e.preventDefault();
        this.setState({isSendingData: true, success: ''});
        const {doc, soumission_id} = this.state;
        const token = sessionStorage.getItem('token');

        let formData = new FormData();
        formData.append('doc_id', docId);
        formData.append('doc_type', type);
        formData.append('soumission_id', soumission_id);
        
        if(doc !== ''){ formData.append('doc', doc); }
        
        Axios.post(`${url}/api/edit-doc?token=${token}`, formData).then(
            res => {
                this.setState({success: "mise à jour effectuée avec succès", doc: ''});
                this.getSoumissionDocs();

                setTimeout(() => {
                    this.setState({isSendingData: false, success: '', error: ''});
                }, 4000);
            }
        );
    }

    renderEtapeThree = () => {
        const { isSendingData } = this.state;

        let types = [
            'protocole-initiale',
            'demande',
            'budget',
            'lettre-d-information',
            'formulaire-de-consentement',
            'recu',
            'lettre',
            'cv',
            'autre'
        ];

        let protocole = null;
        let demande = null;
        let recu = null;
        let budget = null;
        let lettre_d_information = null
        let formulaire_de_consentement = null;
        let cv = null;
        let lettre = null;
        let autre = null;

        return (
            <div className="col-md-12 mt-3 mb-5">
                <div className="etape-three-form mx-auto">
                    {
                        this.state.docs.map((doc, index) => {
                            if(doc.type === 'protocole-initiale'){ protocole = doc }
                            else if(doc.type === 'demande'){ demande = doc }
                            else if(doc.type === 'budget'){ budget = doc }
                            else if(doc.type === 'lettre-d-information'){ lettre_d_information = doc }
                            else if(doc.type === 'formulaire-de-consentement'){ formulaire_de_consentement = doc }
                            else if(doc.type === 'recu'){ recu = doc }
                            else if(doc.type === 'cv'){ cv = doc }
                            else if(doc.type === 'lettre'){ lettre = doc }
                            else if(doc.type === 'autre'){ autre = doc }
                
                            return(
                                <div key={index} className="doc">
                                    <div className="doc-type">
                                        <span>{ doc.type === 'protocole-initiale' ? 'Protocole' : doc.slug }</span>
                                    </div>

                                    <div style={{ cursor: 'default', textTransform: 'none' }} className="doc-value bcg-blue text-white clearfix">
                                        <span
                                            style={{ cursor: 'pointer', textTransform: 'uppercase' }}
                                            className="float-left edit-button bg-white blue-color"
                                            data-toggle="modal"
                                            data-target={`#viewFileModal${index}newSoum`}
                                        >
                                            <span data-toggle="tooltip" data-placement="top" title="Voir"><i class="far fa-eye"></i></span>
                                        </span>
                                        <span
                                            style={{ cursor: 'pointer'}}
                                            className="float-right edit-button bg-white blue-color"
                                            data-toggle="modal"
                                            data-target={`#edit${index}newSoum`}
                                        >
                                            <span data-toggle="tooltip" data-placement="top" title="Modifier"><i class="fas fa-pencil-alt"></i></span>
                                        </span>
                                    </div>

                                    <div className="modal fade" id={`viewFileModal${index}newSoum`} tabindex="-1" role="dialog" aria-labelledby="viewFileModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <embed src={`${url}/public/documents/${doc.valeur}`} width="770" height="500" type='application/pdf'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="modal fade" id={`edit${index}newSoum`} tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    {this.state.success === '' ? this.renderAlertError() : this.renderAlertSuccessAddDoc()}
                                                    <form data-form="multipart" onSubmit={(Event) => this.editDoc(Event, doc.id, doc.type)}>
                                                        <div className="custom-file">
                                                            <input 
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                name="doc"
                                                                onChange={this.onHandleChange}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customFile">
                                                                {this.state.doc == '' ? 'Veuillez charger le fichier' : 'Fichier chargé'}
                                                            </label>
                                                
                                                            {
                                                                isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                                                :   <button type="submit" className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Valider la modification</button>
                                                            }
                                                            
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        protocole === null 
                        ?   <div className="doc">
                                <div className="doc-type">
                                    Protocole
                                </div>
                                <button className="button-blue upload-file" data-toggle="modal" data-target="#protocole-initiale" >+</button>                     
                            </div>
                        :   ''
                    }
                    
                    {
                        demande === null
                        ?   <div className="doc">
                                <div className="doc-type">
                                    Demande de soumission de protocle
                                </div>
                                <button className="button-blue upload-file" data-toggle="modal" data-target="#demande">+</button>
                            </div>
                        :   ''
                    }
                    
                    {
                        budget === null
                        ?   <div className="doc">
                                <div className="doc-type">
                                    Budget détaillé de l'étude
                                </div>
                                <button className="button-blue upload-file" data-toggle="modal" data-target="#budget">+</button>
                            </div>
                        :   ''
                    }
                    
                    {
                        lettre_d_information === null
                        ?   <div className="doc">
                                <div className="doc-type">
                                    Lettre d'information
                                </div>
                                <button className="button-blue upload-file" data-toggle="modal" data-target="#lettre-d-information">+</button>
                            </div>
                        :   ""
                    }
                    
                    {
                        formulaire_de_consentement === null
                        ?   <div className="doc">
                                <div className="doc-type">
                                    Formulaire de consentement
                                </div>
                                <button className="button-blue upload-file" data-toggle="modal" data-target="#formulaire-de-consentement">+</button>
                            </div>
                        :   ""
                    }
                    
                    {
                        recu === null
                        ?   <div className="doc">
                                <div className="doc-type">
                                    Reçu de paiement
                                </div>
                                <button className="button-blue upload-file" data-toggle="modal" data-target="#recu">+</button>
                            </div>
                        :   ""
                    }

                    <div className="doc">
                        <div className="doc-type">
                            Lettres d'engagement des MCR et MCD
                        </div>
                        <button className="button-blue upload-file" data-toggle="modal" data-target="#lettre">+</button>
                    </div>

                    <div className="doc">
                        <div className="doc-type">
                            CV des chercheurs
                        </div>
                        <button className="button-blue upload-file" data-toggle="modal" data-target="#cv">+</button>
                    </div>

                    <div className="doc">
                        <div className="doc-type">
                            Autre type de document
                        </div>
                        <button className="button-blue upload-file" data-toggle="modal" data-target="#autre">+</button>
                    </div>
                </div>

                {/* Upload doc form-modal ...  */}
                {
                    types.map((type, index) => (
                        <div key={index} className="modal fade" id={type} tabindex="-1" role="dialog" aria-labelledby="uploadDocModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        {this.state.success === '' ? this.renderAlertErrorAddDoc() : this.renderAlertSuccessAddDoc()}
                                        <form data-form="multipart" onSubmit={(Event) => this.addDoc(Event, type)}>
                                            <div className="custom-file">
                                                <input 
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="customFile"
                                                    name="doc"
                                                    onChange={this.onHandleChange}
                                                />
                                                <label className="custom-file-label" htmlFor="customFile">
                                                    {this.state.doc == '' ? 'Veuillez charger le fichier' : 'Fichier chargé'}
                                                </label>
                                                
                                                {
                                                    isSendingData ? <input disabled className="mt-3 btn btn-block button-green" value="Chargement ..." style={{ borderRadius: '5px' }} />
                                                    :   <button type="submit" className="mt-3 btn btn-block button-green" style={{ borderRadius: '5px' }}>Valider</button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }

    render() {
        const {isEtapeOneValidated, isEtapeTwoValidated, isEtapeThreeValidated, isLoading, isCanceling, isCanceled} = this.state;

        if(isEtapeThreeValidated){ return <Redirect to="/chercheur/protocoles" /> }
        if(isCanceled){ return <Redirect to="/chercheur" /> }

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5 py-4">
                    <div id="new-protocole-content" className="row">
                        <div className="col-md-12">
                            <div className="row new-protocole bg-white">
                                <div className="col-md-12 mb-4 border-bottom px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Nouveau protocole</span>
                                        <span className="float-right titre text-secondary">
                                            {!isEtapeOneValidated ? 'Informations personnelles' : !isEtapeTwoValidated ? 'Informations du protocoles' : 'Fichiers complémentaires'}
                                        </span>
                                    </div>                                
                                </div>

                                <div className="col-md-2 offset-3 pb-3">
                                    <div className="etape-indicator mx-auto" >
                                        <span className="text blue-color" >Etape 1</span>
                                        <div className="bar bcg-blue" ></div>
                                    </div>
                                </div>
                                <div className="col-md-2 pb-3">
                                    <div className="etape-indicator mx-auto" >
                                        <span className={isEtapeOneValidated ? 'text blue-color' : 'text text-secondary'} >Etape 2</span>
                                        <div className={isEtapeOneValidated ? 'bar bcg-blue' : 'bar bg-secondary'} ></div>
                                    </div>
                                </div>
                                <div className="col-md-2 pb-3">
                                    <div className="etape-indicator mx-auto" >
                                        <span className={isEtapeTwoValidated ? 'text blue-color' : 'text text-secondary'} >Etape 3</span>
                                        <div className={isEtapeTwoValidated ? 'bar bcg-blue' : 'bar bg-secondary'} ></div>
                                    </div>
                                </div>
                                <div className="col-md-3"></div>

                                {!isEtapeOneValidated ? this.renderEtapeOne() : !isEtapeTwoValidated ? this.renderEtapeTwo() : this.renderEtapeThree()}

                                {this.renderAlertError()}

                                <div className="col-md-12 clearfix border-top py-2 pr-3">
                                    {
                                        isEtapeTwoValidated ? <button
                                                                type="submit"
                                                                className="button-default button-green float-right"
                                                                onClick={this.onClickNext} >Soumettre</button>
                                                            : <button
                                                                disabled={isLoading}
                                                                className="button-default button-green float-right"
                                                                onClick={this.onClickNext} >{isLoading ? 'Chargement' : 'Suivant'}</button>
                                    }
                                    {isEtapeOneValidated ? <button className="button-default button-red float-right mr-1" data-target="#modalCancelSoumission" data-toggle="modal">Annuler</button> : ''}
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>


                    {/* Modal Confirm Cancel Soumission ... */}
                <div className="modal fade" id="modalCancelSoumission" tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="title text-center" id="smallModalLabel" style={{ fontFamily: 'lato' }}>Confirmation de l'annulation de la soumission</h6>
                            </div>
                            <div className="modal-body text-center">
                                {this.state.successOnCancel === '' ? this.renderAlertErrorOnCancel() : this.renderAlertSuccessOnCancel()}
                                <b className="text-secondary" style={{ fontFamily: 'lato' }}>Etes-vous sûre de vouloir annuler votre soumission</b>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="button-default button-blue px-4" data-dismiss="modal">Non</button>
                                <button
                                    disabled={isCanceling}
                                    className="button-default button-green px-4"
                                    onClick={this.onCancelSoumission}
                                >{isCanceling ? 'Annulation en cours' : 'Oui'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NewProtocole;