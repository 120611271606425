import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';

import './Membre.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import MembreOverview from '../../../components/membre-overview/MembreOverview';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class ListeMembres extends Component {
    constructor() {
        super();
        this.state = {
            membres: [],
            isLoading: true,
            isEmptyRecords: false
        }
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');
        Axios.get(`${url}/api/membres?token=${token}`).then(
            res => {
                const {membres, count} = res.data;
                if(count == 0) {
                    this.setState({isEmptyRecords: true});
                }
                this.setState({isLoading: false, membres});
            }
        );
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    showEmptyRecordsAlert = () => {
        if(this.state.isEmptyRecords){
            return (
                <div className="alert alert-info m-5">
                    Aucun membre n'est ajouté actuellememnt !
                </div>
            )
        } else {
            return '';
        }
    }

    render() {
        const {membres} = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="liste-membres-content" classNam="row">
                        <div className="col-md-12">
                            <div className="row liste-membres bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Membres du commité</span>
                                        <div className="float-right">
                                            <Link to="/secretaire/new-membre" className="btn button-default button-green">Nouveau membre</Link>
                                        </div>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0 pb-5">
                                    {this.showLoader()}
                                    {this.showEmptyRecordsAlert()}

                                    {membres.map((membre, index) => (
                                        <MembreOverview key={index} membre={membre} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ListeMembres;