import React, { Component } from 'react';

import './Overview.css';

class Overview extends Component {
    constructor() {
        super();
        this.state = {
            overview: {}
        }
    }
    
    componentDidMount() {
        this.setState({overview: this.props.overview});
    }

    render() {
        const {overview} = this.state;
        
        return (
            <div className="col-md-2 pl-0">
                <div className="overview-box">
                    <span className={overview.etat === 'done' ? 'status bcg-green': 'status bg-danger'}></span>
                    <span className="titre text-secondary">{overview.titre}</span>
                    <span className="value">{overview.valeur}</span>
                    <span className={overview.etat === 'done' ? 'alert-overview green-color': 'alert-overview text-danger'}>{overview.alerte}</span>
                </div>
            </div>
        );
    }
}

export default Overview;