import React, { Component } from 'react';

import {url} from '../../Config';

import './ListeActivites.css';

class ListeActivites extends Component {
    constructor() {
        super();
        this.state = {
            activite: {},
            from: ''
        }
    }
    
    componentDidMount() {
        this.setState({activite: this.props.activite, from: this.props.from});
    }

    render() {
        const {activite, from} = this.state;
        
        return (
            <div className="activite-box">
                <img className="activite-img" src={`${url}/public/img/${activite.image}`} />
                <div className="activite-infos">
                    <span className="auteur">
                        <strong className="bcg-green mr-2" style={{ display: 'inline-block', width: '5px', height: '5px', borderRadius: '100%'}}></strong>
                        {activite.auteur}
                    </span>
                    <span className="objet" onClick={this.props.onSelect}>{activite.titre}</span>
                    {/* {activite.type === 'message' ? <span className="notif blue-color">2 nouveaux messages</span> : ''} */}
                    <span className="apercu">{activite.apercu}</span>
                </div>
            </div>
        );
    }
}

export default ListeActivites;