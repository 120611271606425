import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../Config';

import './Auth.css';
import AuthServices from '../../services/AuthServices';

class Auth extends Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            error: {
                username: null,
                password: null
            },
            errorApi: null,
            redirect: false,
            redirectTo: '',
            isLoading: false
        }
        let AuthServices;
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        if(token !== null) { this.setState({redirectTo: '/', redirect: true}) }
    }

    onHandleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'username':
                this.setState({username: value})
                break;
            case 'password':
                this.setState({password: value})
                break;        
            default:
                break;
        }
    }

    onSubmitForm = (e) => {
        e.preventDefault();
        this.setState({errorApi: null, isLoading: true});
        const {username, password, error} = this.state;

        if(username === '') {
            error.username = 'Le champ username est obligatoire';
        } else {
            error.username = null;
        }
        if(password === '') {
            error.password = 'Le champ mot de passe est obligatoire';
        } else {
            error.password = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({isLoading: false, error})
        } else {            
            const {username, password} = this.state;
            this.sendFormData(username, password);
        }
    }

    sendFormData = (username, password) => {
        let formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        Axios.post(`${url}/api/login`, formData)
            .then(
                res => {
                    if(res.data.status === 'error') {                        
                        this.setState({errorApi: res.data.error, isLoading: false});
                    } else {
                        const {token} = res.data;
                        Axios.get(`${url}/api/user?token=${token}&username=${username}`).then(
                            res => {
                                if(res.data.status === 'error') {
                                    let errorApi;
                                    res.data.errors.token ? errorApi = res.data.errors.token[0] : res.data.errors.username ? errorApi = res.data.errors.username[0] : errorApi = null
                                    this.setState({errorApi, isLoading: false});
                                } else {
                                    const {user} = res.data;
                                    sessionStorage.setItem('token', token);
                                    sessionStorage.setItem('username', username);
                                    sessionStorage.setItem('profil', user.profil);
                                    switch (user.profil) {
                                        case 'secretaire':
                                            this.setState({redirectTo: '/secretaire'});
                                            break;
                                        case 'chercheur':
                                            this.setState({redirectTo: '/chercheur'});
                                            break;
                                        case 'membre':
                                            this.setState({redirectTo: '/membre'});
                                            break;
                                    
                                        default:
                                            break;
                                    }
                                    this.setState({isLoading: false, redirect: true});
                                }
                            }
                        )
                    }
                },

                (err) => {
                    console.log('error', err)
                    this.setState({isLoading: false});
                }
            )
    }

    renderAlertError = () => {
        const {errorApi, error} = this.state;
        if(errorApi !== null){
            return (
                <div className="alert alert-danger">
                    {errorApi}
                </div>
            )
        }
        else if(error.username !== null){            
            return (
                <div className="alert alert-danger">
                    {error.username}
                </div>
            );
        }
        else if(error.password !== null){            
            return (
                <div className="alert alert-danger">
                    {error.password}
                </div>
            );
        } else {
            return '';
        }
            
    }

    render() {
        const {redirect, redirectTo, isLoading} = this.state;

        if(redirect) {
            return <Redirect to={redirectTo} />
        }

        return (
            <div className="container-fluid p-0">
                <div className="col-md-12 auth-content">
                    <img className="auth-background" src="/img/background.jpg" alt="auth background"/>
                    <div className="auth-opacity"></div>
                    <div className="form-side">
                        <img src={`/img/logo.png`} className="auth-logo" alt="logo cners" />
                        <h5 className="auth-titre">Veuillez vous authentifier</h5>
                        {this.renderAlertError()}
                        <form className="auth-form" onSubmit={this.onSubmitForm}>
                            <input
                                type="text"
                                name="username"
                                value={this.state.username}
                                className="auth-input"
                                placeholder="username"
                                onChange={this.onHandleChange}
                            />
                            <input
                                type="password"
                                name="password"
                                value={this.state.password}
                                className="auth-input"
                                placeholder="Mot de passe"
                                onChange={this.onHandleChange}
                            />
                            <button type="submit" disabled={isLoading} className="button-green auth-submit">
                                {!isLoading ? 'Connexion' : <strong>Chargement...</strong>}
                            </button>
                            <Link to="/mot-de-passe-oublie" className="mdp-oublie-link">J'ai oublié mon mot de passe</Link>
                        </form>

                        <h5 className="auth-titre">Vous ne disposez pas de compte ?</h5>
                        <Link to="/signup" className="btn button-blue auth-submit">Inscrivez-vous</Link>
                        <h6 className="auth-titre">Rendez vous sur le site <a href="https://cners.sn">www.cners.sn</a></h6>
                    </div>
                </div>
            </div>
        );
    }
}

export default Auth;