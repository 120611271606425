import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';

import './Protocoles.css';
import ProtocoleOverview from '../../../components/protocole-overview/ProtocoleOverview';
import Chat from '../../../components/chat/Chat';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class ListeProtocolesChercheur extends Component {
    constructor() {
        super();
        this.state = {
            soumissions: [],
            protocoles: [],
            isSoumissionLoading: false,
            isLoading: true,
            isEmptyRecords: false
        };
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'chercheur'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/get-my-soumissions?token=${token}`).then(
            res => {
                const {soumissions} = res.data;
                let countSoumissions = res.data.count;
                Axios.get(`${url}/api/get-my-protocoles?token=${token}`).then(
                    resProto => {
                        const {protocoles} = resProto.data;
                        let countProtocoles = resProto.data.count;
                        if(countSoumissions == 0 && countProtocoles == 0){
                            this.setState({isEmptyRecords: true});
                        }
                        this.setState({protocoles, soumissions});

                        Axios.get(`${url}/api/is-soumission-loading?token=${token}`).then(
                            res1 => {
                                const {isSoumissionLoading} = res1.data;
                                this.setState({isLoading: false, isSoumissionLoading});
                            }
                        );
                    }
                );
            }
        );
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    showEmptyRecordsAlert = () => {
        if(this.state.isEmptyRecords){
            return (
                <div className="alert alert-info m-5">
                    Vous n'avez pas encore de protocole soumis !
                </div>
            )
        } else {
            return '';
        }
    }

    render() {
        const {soumissions, protocoles} = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5 pt-2">
                    <div id="liste-protocoles-chercheur-content" className="row">
                        <div className="col-md-12">
                            <div className="row liste-protocoles-chercheur bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Mes protocoles</span>
                                        <Link to="/chercheur/new-protocole" className="float-right btn button-default button-green">
                                            {this.state.isSoumissionLoading ? 'Continuer ma soumission' : 'Nouveau protocole'}
                                        </Link>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0 pb-5">
                                    {this.showLoader()}
                                    {this.showEmptyRecordsAlert()}

                                    {soumissions.map((soumission, index) => (
                                        <ProtocoleOverview
                                            key={index}
                                            from="chercheur"
                                            type="soumission"
                                            protocole={soumission}
                                        />
                                    ))}

                                    {protocoles.map((protocole, index) => (
                                        <ProtocoleOverview
                                            key={index}
                                            from="chercheur"
                                            type="protocole"
                                            protocole={protocole}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ListeProtocolesChercheur;