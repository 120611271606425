import React, { Component } from 'react';

import './inbox.css';
import Chat from '../../../components/chat/Chat';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class inboxChercheur extends Component {
    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'chercheur'){ this.props.history.goBack(); }
    }
    
    render() {
        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5 pt-2">
                    <div id="liste-enregistrements-content" className="row">
                        <div className="col-md-12">
                            <Chat />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default inboxChercheur;