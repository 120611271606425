import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';

import './AccueilChercheur.css';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class AccueilChercheur extends Component {
    constructor() {
        super();
        this.state = {
            me: {},
            data: [],
            isSoumissionLoading: false,
            isLoading: true,
        }
    }

    componentDidMount(){
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'chercheur'){ this.props.history.goBack(); }

        const token = sessionStorage.getItem('token');
        const username = sessionStorage.getItem('username');

        Axios.get(`${url}/api/user?token=${token}&username=${username}`).then(
            resUser => {
                const {user} = resUser.data;
                this.setState({me: user});
                
                Axios.get(`${url}/api/get-my-statistics?token=${token}`).then(
                    resStats => {
                        const {data} = resStats.data;
                        this.setState({data});

                        Axios.get(`${url}/api/is-soumission-loading?token=${token}`).then(
                            res => {
                                const {isSoumissionLoading} = res.data;
                                this.setState({isLoading: false, isSoumissionLoading});
                            }
                        );
                    }
                );
            }
        );
    }

    render() {
        const {me, data, redirect} = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5 pt-2">
                    <div id="accueil-chercheur-content" className="row">
                        <div className="col-md-8 pl-0 pr-4">
                            <div className="row accueil-chercheur bg-white">
                                <div className="col-md-12 px-4 pt-4 pb-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary"><strong>Boujour</strong>, {me.prenom} {me.nom}</span>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0">
                                    <div id="accueil-chercheur-box" className="row">
                                        <p className="presentation text-secondary">
                                            <span className="ennonce">
                                                cette interface sert d'interaction avec le secretariat du cners en vous offrant la possibilte de :
                                            </span>
                                            <span className="point">- Déposer un protocole</span>
                                            <span className="point">- Communiquer avec le secrétaire</span>
                                            <span className="point">- Suivre instantanément l'état d'avancement de l'étude du protocole</span>
                                            <span className="point">- Archiver l'ensemble des documents relatives au protocole déposé</span>
                                        </p>
                                        
                                        <div className="clearfix col-md-12 pb-1">
                                            <Link to="/chercheur/new-protocole" className="btn button-default button-green float-right">
                                                {this.state.isSoumissionLoading ? 'Continuer ma soumission' : 'Nouveau protocole'}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pl-3">
                            <div className="row chercheur-presentation bg-white">
                                <div className="top-side bcg-blue">
                                    <div className="text text-white">
                                        <span className="text-left" style={{ fontWeight: '600' }}>{me.prenom} {me.nom}</span> <br/>
                                        <span className="text-left">{me.fonction}</span>
                                    </div>
                                    <img className="top-side-img" src={`${url}/public/img/${me.avatar}`} alt="chercheur avatar" />
                                </div>

                                <div className="content-side">
                                    <div className="element clearfix">
                                        <div className="float-left">
                                            <img src={`${url}/public/img/${data.icon_soumission}`} alt="image illustratif" />
                                            <span className="intitule">Dossiers déposés :</span>
                                        </div>
                                        <span className="float-right valeur green-color p-1">{data.nbrSoumissions}</span>
                                    </div>
                                    <div className="element clearfix">
                                        <div className="float-left">
                                            <img src={`${url}/public/img/${data.icon_protocole}`} alt="image illustratif" />
                                            <span className="intitule">Protocoles :</span>
                                        </div>
                                        <span className="float-right valeur green-color p-1">{data.nbrProtocoles}</span>
                                    </div>
                                    <div className="element clearfix">
                                        <div className="float-left">
                                            <img src={`${url}/public/img/${data.icon_avis}`} alt="image illustratif" />
                                            <span className="intitule">Avis :</span>
                                        </div>
                                        <span className="float-right valeur green-color p-1">{data.nbrAvis}</span>
                                    </div>
                                    <div className="element clearfix">
                                        <div className="float-left">
                                            <img src={`${url}/public/img/${data.icon_autorisation}`} alt="image illustratif" />
                                            <span className="intitule">Authorisations :</span>
                                        </div>
                                        <span className="float-right valeur green-color p-1">{data.nbrAutorisations}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AccueilChercheur;