import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../../Config';

import './Session.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class EditSession extends Component {
    constructor() {
        super();
        this.state = {
            session_id: '',
            objet: '',
            date: '',
            isLoading: false,
            success: ''
        }
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');
        const {session_id} = this.props.match.params;
        this.setState({session_id});

        Axios.get(`${url}/api/get-session?token=${token}&session_id=${session_id}`).then(
            res => {
                const {session} = res.data;
                this.setState({
                    objet: session.objet,
                    date: session.date
                });
            }
        );
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'objet':
                this.setState({objet: value});
                break;
            case 'date':
                this.setState({date: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('token');
        this.setState({isLoading: true, success: ''});
        const {objet, date, session_id} = this.state;
        
        let formData = new FormData();
        formData.append('session_id', session_id);
        if(objet !== ''){ formData.append('objet', objet); }
        if(date !== ''){ formData.append('date', this.state.date); }

        Axios.post(`${url}/api/edit-session?token=${token}&session_id=${session_id}`, formData).then(
            res => {
                this.setState({isLoading: false, success: 'Session mise à jour avec succès'})
                setTimeout(() => {
                    this.setState({success: ''})
                }, 3000);
            },

            err => this.setState({isLoading: false})
        )
    }

    renderAlertSuccess = () => {
        const {success} = this.state;

        if(success !== '') {            
            return (
                <div className="alert alert-success">
                    {success}
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        const { isLoading } = this.state;

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="new-session-content" classNam="row">
                        <div className="col-md-12">
                            <div className="row new-session bg-white">
                                <div className="col-md-12 py-2">
                                    <div className="new-session-form-content mx-auto">
                                        <h5 className="new-session-titre">Edition de Session</h5>
                                        {this.renderAlertSuccess()}

                                        <form className="new-session-form" onSubmit={this.handleSubmit}>
                                            <input
                                                type="text"
                                                className="new-session-input"
                                                placeholder="Objet de la session"
                                                name="objet"
                                                value={this.state.objet}
                                                onChange={this.handleChange}
                                            />
                                            <input
                                                type="date"
                                                className="new-session-input"
                                                placeholder="Date de la session"
                                                name="date"
                                                value={this.state.date}
                                                onChange={this.handleChange}
                                            />
                                            <button type="submit" className="button-green new-session-submit">
                                                {!isLoading ? 'Mettre à jour' : <strong>Chargement...</strong>}
                                            </button>
                                        </form>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EditSession;