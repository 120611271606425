import React, { Component } from 'react';
import Axios from 'axios';

import {url} from '../../Config';

import NotificationOverview from '../../components/notification-overview/NotificationOverview';
import ListeOverviews from '../../components/liste-overviews/ListeOverviews';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';

class Notifications extends Component {
    constructor() {
        super();
        this.state = {
            notifications: [],
            isLoading: true,
            isEmptyRecords: false,
            counter: null,
        };
    }

    componentDidMount(){        
        const token = sessionStorage.getItem('token');

        this.markAsSeen();

        Axios.get(`${url}/api/my-notifications?token=${token}`).then(
            res => {
                const {notifications, count} = res.data;
                if(count == 0){
                    this.setState({isEmptyRecords: true});
                }
                this.setState({notifications, isLoading: false});
            }
        );
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    showEmptyRecordsAlert = () => {
        if(this.state.isEmptyRecords){
            return (
                <div className="alert alert-info m-5">
                    Vous n'avez pas de notifications !
                </div>
            )
        } else {
            return '';
        }
    }

    markAsSeen = () => {
        const token = sessionStorage.getItem('token');

        Axios.get(`${url}/api/mark-as-seen?token=${token}`).then(
            res => {
                this.setState({counter: 0});
            }
        );
    }

    render() {
        const {notifications, counter} = this.state;

        return (
            <>
                <Navbar counter={counter} />
                <Sidebar />
                
                <div className="col-md-10 offset-md-2 mt-5 pt-2">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="liste-protocoles-chercheur-content" className="row">
                        <div className="col-md-12">
                            <div className="row liste-protocoles-chercheur bg-white">
                                <div className="col-md-12 px-4 py-2">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Mes Notifications</span>
                                    </div>                                
                                </div>

                                <div className="col-md-12 px-0 pb-5">
                                    {this.showLoader()}
                                    {this.showEmptyRecordsAlert()}

                                    {notifications.map((notification, index) => (
                                        <NotificationOverview
                                            key={index}
                                            notification={notification}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Notifications;