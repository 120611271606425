import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../Config';

import './Auth.css';

class MotDePasseOublie extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            username: '',
            error: {
                email: null,
                username: null,
            },
            success: '',
            redirect: false,
            isLoading: false
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        let error = {
            email: null,
            username: null,
        }
        this.setState({error});

        switch (name) {
            case 'email':
                this.setState({email: value});
                break;
            case 'username':
                this.setState({username: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {email, username, error} = this.state;
        
        if(username === '') {
            error.username = 'Le champ nom d\'utilisateur est obligatoire';
        } else {
            error.username = null;
        }
        if(email === '') {
            error.email = 'Le champ email est obligatoire';
        } else {
            error.email = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({isLoading: false, error})
        } else {
            this.sendFormData();
        }
    }

    sendFormData = () => {
        this.setState({success: ''});

        let formData = new FormData();
        formData.append('username', this.state.username);
        formData.append('email', this.state.email);

        Axios.post(`${url}/api/mot-de-passe-oublie`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;
                        
                        if(errors.username !== undefined) {
                            error.username = errors.username[0]
                        } else {error.username = null}
                        if(errors.email !== undefined) {
                            error.email = errors.email[0]
                        } else {error.email = null}

                        this.setState({isLoading: false, error});
                    } else {
                        this.setState({isLoading: false, success: 'Un email avec le lien de modifiction de votre mot de passe vous a été envoyé'});
                        setTimeout(() => {
                            this.setState({success: '', redirect: true});
                        }, 4000);
                    }
                },

                (err) => {
                    console.log('error', err)
                    this.setState({isLoading: false});
                }
            )
    }

    renderAlertSuccess = () => {
        const {success} = this.state;

        if(success !== ''){            
            return (
                <div className="alert alert-success">
                    {success}
                </div>
            );
        } else {
            return '';
        }
    }

    renderAlertError = () => {
        const {error} = this.state;
        
        if(error.username !== null){            
            return (
                <div className="alert alert-danger">
                    {error.username}
                </div>
            );
        }
        else if(error.email !== null){            
            return (
                <div className="alert alert-danger">
                    {error.email}
                </div>
            );
        }
        else {
            return '';
        }
    }

    render() {
        const { redirect, isLoading } = this.state;
        if(redirect) {
            return <Redirect to='/' />
        }

        return (
            <div className="container-fluid p-0">
                <div className="col-md-12 auth-content">
                    <img className="auth-background" src="/img/background.jpg" alt="auth background"/>
                    <div className="auth-opacity"></div>
                    <div className="form-side">
                        <img src="/img/logo.png" className="auth-logo" alt="logo cners" />
                        <h5 className="auth-titre">Mot de passe oublié</h5>
                        {this.state.success === '' ? this.renderAlertError() : this.renderAlertSuccess()}
                        <form className="auth-form" onSubmit={this.handleSubmit}>
                            <input
                                type="text"
                                className="auth-input"
                                placeholder="Votre Username"
                                name="username"
                                value={this.state.username}
                                onChange={this.handleChange}
                            />
                            <input
                                type="email"
                                className="auth-input"
                                placeholder="Votre Email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />

                            <button type="submit" disabled={isLoading} className="button-green auth-submit">
                                {!isLoading ? 'Envoyer' : <strong>Chargement...</strong>}
                            </button>
                        </form>

                        <h5 className="auth-titre">Vous ne disposez pas de compte ?</h5>
                            <Link to="/signup" className="btn button-blue auth-submit">Inscrivez-vous</Link>
                        <h6 className="auth-titre">Rendez vous sur le site <a href="https://cners.sn">www.cners.sn</a></h6>
                    </div>
                </div>
            </div>
        );
    }
}

export default MotDePasseOublie;