import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../../Config';

import './Depot.css';
import ListeOverviews from '../../../components/liste-overviews/ListeOverviews';
import MessageForm from '../../../components/message/MessageForm';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

class Depot extends Component {
    constructor() {
        super();
        this.state = {
            depot: {},
            docs: [],
            redirect: false,
            isLoading: true,
        }
    }

    componentDidMount() {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'secretaire'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');
        let id = this.props.match.params.depot_id;
        
        Axios.get(`${url}/api/soumission?token=${token}&soumission_id=${id}`).then(
            res => {
                const {protocole, docs} = res.data;

                this.setState({isLoading: false, depot: protocole, docs});
            }
        );
    }

    showLoader = () => {
        if(this.state.isLoading){
            return (
                <div class="d-flex justify-content-center">
                    <strong>Chargement...</strong>
                </div>
            )
        } else {
            return '';
        }
    }

    renderDoc = (slug, valeur, fileType, index) => {
        return (
            <>
                <div key={index} className="depot-doc">
                    <div className="depot-doc-type">
                        {slug}
                    </div>
                    <div className="depot-doc-value bcg-blue text-white" data-toggle="modal" data-target={`#viewFileModal${index}depot`}>
                        {fileType}
                    </div>
                </div>

                <div className="modal fade" id={`viewFileModal${index}depot`} tabindex="-1" role="dialog" aria-labelledby="viewFileModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <embed src={`${url}/public/documents/${valeur}`} width="770" height="500" type='application/pdf'/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    saveSoumission = () => {
        const token = sessionStorage.getItem('token');
        let id = this.state.depot.id;
        
        Axios.get(`${url}/api/save-soumission?token=${token}&soumission_id=${id}`).then(
            res => {
                this.setState({redirect: true});
            }
        );
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const {depot, docs, redirect, isLoading} = this.state;

        if(redirect) { return (<Redirect to='/secretaire/enregistrements' />) }

        return (
            <>
                <Navbar />
                <Sidebar />
                <div className="col-md-10 offset-md-2 mt-5">
                    <div className="row">
                        <ListeOverviews />
                    </div>

                    <div id="depot-content" className="row">
                        <div className="col-md-8 pl-0 pr-4 pb-2">
                            <div className="row depot bg-white">
                                <div className="col-md-12 px-4 py-3">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Dépôt</span>
                                        <span
                                            className="float-left bcg-blue text-center py-auto text-white ml-2 mt-1"
                                            style={{ display: 'block', width: '35px', height: '20px', borderRadius: '10px', cursor: 'pointer' }}
                                            onClick={this.goBack}
                                        ><i class="fas fa-chevron-left"></i></span>
                                    </div>
                                </div>

                                <div className="col-md-12 px-0">
                                    {isLoading ? this.showLoader() : 
                                        <div id="depot-box" className="row">
                                            <div className="col-md-3">
                                                <span className="depot-label">Numéro :</span>
                                                <span className="depot-label">Thème :</span>
                                                <span className="depot-label">Titre :</span>
                                                <span className="depot-label">Auteur :</span>
                                                <span className="depot-label">Date de dépôt :</span>
                                            </div>
                                            <div className="col-md-9">
                                                <span className="depot-input">{depot.numero}</span>
                                                <span className="depot-input">{depot.theme}</span>
                                                <span className="depot-input">{depot.titre}</span>
                                                <span className="depot-input">{depot.auteur}</span>
                                                <span className="depot-input">{depot.dateDepot}</span>
                                            </div>

                                            <div className="col-md-12 clearfix">
                                                <span
                                                    className="float-right bcg-blue text-center py-auto text-white mr-2 mt-1"
                                                    style={{ display: 'block', width: '90px', height: '20px', borderRadius: '5px', cursor: 'pointer' }}
                                                    data-toggle="modal" data-target="#voirPlusModal"
                                                >Voir Plus</span>
                                            </div>

                                            <div className="col-md-12">
                                                { docs.map((doc, index) => (
                                                    this.renderDoc(doc.slug, doc.valeur, 'PDF', index)
                                                ))}
                                            </div>
                                        </div>
                                    }

                                    {
                                        depot.archived !== 1 ?
                                            <div className="col-md-12 py-3 clearfix">
                                                <button
                                                    className="btn button-default button-green float-right"
                                                    onClick={this.saveSoumission}
                                                >Enregistrer</button>
                                            </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pl-3">
                            <div className="row progress-side bg-white" style={{ borderRadius: '5px' }}>
                                <div className="col-md-12 px-3 py-3">
                                    <div className="clearfix">
                                        <span className="float-left titre text-secondary">Messagerie</span>
                                    </div>
                                </div>

                                <div className="col-md-12 pb-5">
                                    <MessageForm />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="voirPlusModal" tabindex="-1" role="dialog" aria-labelledby="voirPlusModalModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="col-12 pt-3"><strong>Numéro :</strong> {depot.numero}</div>
                                    <div className="col-12 pt-3"><strong>Titre :</strong> {depot.titre}</div>
                                    <div className="col-12 pt-3"><strong>Auteur :</strong> {depot.auteur}</div>
                                    <div className="col-12 pt-3"><strong>Service :</strong> {depot.service}</div>
                                    <div className="col-12 pt-3"><strong>Sponsor :</strong> {depot.sponsor}</div>
                                    <div className="col-12 pt-3"><strong>Thèmes :</strong> {depot.theme}</div>
                                    <div className="col-12 pt-3"><strong>Types :</strong> {depot.type}</div>
                                    <div className="col-12 pt-3"><strong>Budget :</strong> {depot.budget} fcfa</div>
                                    <div className="col-12 pt-3"><strong>Site :</strong> {depot.site}</div>
                                    <div className="col-12 pt-3"><strong>Cibles :</strong> {depot.cibles}</div>
                                    <div className="col-12 pt-3"><strong>Date de début :</strong> {depot.debut}</div>
                                    <div className="col-12 pt-3"><strong>Date de fin :</strong> {depot.fin}</div>
                                    <div className="col-12 pt-3"><strong>Durée :</strong> {depot.duree}</div>
                                    <div className="col-12 pt-3 text-justify"><strong>Résumé du projet :</strong> {depot.resume}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Depot;