import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../Config';

import './ProtocoleOverview.css';

class ProtocoleOverview extends Component {
    constructor() {
        super();
        this.state = {
            protocole: {},
            from: ''
        }
    }
    
    componentDidMount() {
        this.setState({protocole: this.props.protocole});
        this.setState({from: this.props.from});
    }

    downloadProtocole = () => {
        const profil = sessionStorage.getItem('profil');
        if(profil !== 'membre'){ this.props.history.goBack(); }
        
        const token = sessionStorage.getItem('token');
        const { protocole } = this.state;

        Axios({
            url: `${url}/api/download-protocole/${protocole.id}?token=${token}`,
            method: 'GET',
            responseType: 'blob'
        }).then(
            res => {
                const url_b = URL.createObjectURL(new Blob([res.data],{type:'application/zip'}));
                const zipName = protocole.reference.replace('/', '_');
                
                const link = document.createElement('a');
                link.href = url_b;
                link.setAttribute('download', zipName + '.zip');
                
                document.body.appendChild(link);
                link.click();
            }
        );
    }

    // Render this where we are at the Membre space ... 
    renderProtocolesForMembreView = () => {
        const {protocole} = this.state;

        return (
            <div className="depots-overview-infos">
                <span className="auteur">{protocole.auteur}</span>
                <span className="objet">
                    <Link to={`/membre/protocole/${protocole.id}`}>{protocole.reference}: </Link>
                </span>
                <span className="apercu">{protocole.titre}</span>
                <button
                    className="duree button-default button-blue"
                    onClick={this.downloadProtocole}
                >
                    Télécharger le protocole
                </button>
            </div>
        );
    }

    // Render this where we are at the Secretaire space ... 
    renderDepotsView = () => {
        const {protocole} = this.state;

        return (
            <div className="depots-overview-infos">
                <span className="auteur">{protocole.auteur}</span>
                <span className="objet">
                    {
                        this.props.type
                        ?   this.props.type == 'enregistrement' 
                            ?   <Link to={`/secretaire/enregistrement/${protocole.id}`}>{protocole.reference}: </Link>
                            :   <Link to={`/secretaire/depot/${protocole.id}`}>
                                    {this.props.type == 'amendement' ? protocole.reference : protocole.theme}:
                                </Link>
                        :   <Link to={`/secretaire/depot/${protocole.id}`}>{protocole.theme}: </Link>
                    }
                </span>
                <span className="apercu">{protocole.titre}</span>
                {protocole.duree ? <span className="duree">{protocole.duree} min ago</span> : ''}
            </div>
        );
    }

    // Render this where we are at the Chercheur space ... 
    renderProtocolesView = () => {
        const {protocole} = this.state;
        const{type} = this.props;
        
        return (
            <div className="protocoles-overview-infos">
                <span className="objet">
                    {
                        type === 'soumission'
                        ?   <Link to={`/chercheur/protocole/${protocole.id}/${type}`}>{protocole.theme}: </Link>
                        :   type === 'protocole'
                        ?   <Link to={`/chercheur/protocole/${protocole.id}/${type}`}>{protocole.numero}: </Link>
                        :   `${protocole.numero}: `
                    }
                </span>
                <span className="apercu">
                    {type === 'amendement' ? `Etape ${protocole.etape}` : protocole.titre}
                </span>
                <div className="level">
                    <div className="clearfix">
                        {
                            type === 'soumission'
                            ? <Link to={`/chercheur/modifier-soumission/${protocole.id}`} className="blue-color float-right">Modifier ma soumission</Link>
                            :   type === 'amendement'
                            ?   <Link to={`/chercheur/new-protocole/${protocole.id}`} className="blue-color float-right">Compléter la soumission</Link>
                            : <span className="green-color float-right">Protocole dèjà enregistré</span>
                        }
                    </div>
                    <div className="level-bar">
                        {
                            type === 'soumission' || type === 'amendement'
                            ? <span className="depot bcg-blue"></span>
                            : <span className="valide bcg-green"></span>
                        }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {from, protocole} = this.state;

        return (
            <div className="protocole-overview-box">
                <img className="protocole-overview-img" src={`${url}/public/img/${protocole.image}`} />
                { 
                    from === 'secretaire' ? 
                        this.renderDepotsView() 
                    : from === 'membre' ? this.renderProtocolesForMembreView()
                    : this.renderProtocolesView() 
                }
            </div>
        );
    }
}

export default ProtocoleOverview;